import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "../Snackbar";

interface FormDeleteButtonProps {
  label: string;
  handleDelete: () => Promise<void>;
  warningMessage: string;
  successMessage: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  dialog: {
    padding: theme.spacing(2),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const FormDeleteButton: React.FC<FormDeleteButtonProps> = ({
  label,
  handleDelete,
  warningMessage,
  successMessage,
}) => {
  const [deleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { setSnackbarMessage } = useSnackbar();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteClick = async () => {
    setDeleting(true);
    await handleDelete();
    setSnackbarMessage(successMessage);
    handleClose();
  };
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.button}
        onClick={handleOpen}
        fullWidth
        startIcon={<DeleteIcon />}
      >
        {label}
      </Button>
      <Dialog open={open}>
        <div className={classes.dialog}>
          <Typography variant="h5" gutterBottom>
            <strong>Confirm Deletion</strong>
          </Typography>
          <Typography color="textSecondary">{warningMessage}</Typography>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={classes.grid}
          >
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.deleteButton}
                onClick={handleDeleteClick}
                variant="contained"
                disabled={deleting}
              >
                {deleting ? "Deleting..." : "Delete"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default FormDeleteButton;
