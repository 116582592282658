import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ImageInputValues } from "../../constants";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    background: theme.palette.grey[200],
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    maxWidth: "50%",
    flexBasis: "50%",
    flexShrink: 0,
    height: 240,
  },
  imageGrid: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "auto",
    width: "100%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover",
    objectPosition: "center center",
    width: "100%",
    height: "100%",
  },
  infoContainer: {
    paddingTop: theme.spacing(2),
    "& p": {
      fontSize: "1rem",
      fontFamily: "inherit",
      lineHeight: 1.5,
      marginTop: 0,
    },
  },
}));

const ImagePreview: React.FC<{
  src: string;
}> = ({ src }) => {
  const classes = useStyles();
  return (
    <div className={classes.imageContainer}>
      {src ? (
        <img src={src} className={classes.image} alt="Placeholder" />
      ) : (
        <div>
          <Typography variant="body1" gutterBottom>
            MACHINE IMAGE
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Recommended Size: 1000x800
          </Typography>
        </div>
      )}
    </div>
  );
};

interface FormPreviewProps {
  imageInputValues: ImageInputValues[];
  name: string;
  manufacturer: string;
  description: string;
}
const MachineFormPreview: React.FC<FormPreviewProps> = ({
  imageInputValues,
  name,
  manufacturer,
  description,
}) => {
  const classes = useStyles();
  const imageSources = imageInputValues.map(
    ({ blob, imageURL }) => blob || imageURL
  );
  if (imageSources.length < 2) {
    for (let i = imageSources.length; i < 2; i++) {
      imageSources.push("");
    }
  }
  return (
    <div>
      <div className={classes.imageGrid}>
        {imageSources.map((src, index) => {
          return <ImagePreview key={`preview-${index}`} src={src} />;
        })}
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="h4" gutterBottom>
          <strong>
            {manufacturer} {name}
          </strong>
        </Typography>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
};

export default MachineFormPreview;
