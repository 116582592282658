import React from "react";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import ProductFamilyForm from "./ProductFamilyForm";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";

const PageComponent: React.FC<{
  data: any[];
  loading: boolean;
}> = ({ data, loading }) => {
  const { openDialog, updateEditValues } = useFormDialog();
  return (
    <VirtualizedTable
      tableWidth={500}
      searchPlaceholder="Search by product family"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "id",
          width: 450,
          label: "Name",
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues(rowData);
              openDialog(true);
            };
            return (
              <Tooltip title="View">
                <span>
                  <IconButton onClick={handleClick}>
                    <LaunchIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
    />
  );
};

const ProductFamilyPage: React.FC = () => {
  const {
    state: {
      data: { productFamilies },
      metadata: { loading },
    },
  } = useStore();
  return (
    <FormDialogProvider
      formLabel="Product Family"
      FormComponent={<ProductFamilyForm />}
      maxWidth="sm"
      editLabel="View"
    >
      <PageComponent data={productFamilies} loading={loading} />
    </FormDialogProvider>
  );
};

export default ProductFamilyPage;
