import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 2,
    right: 2,
    padding: 4,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const RequestDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  title: string;
}> = ({ open, handleClose, children, title }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <div className={classes.root}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography variant="h6" className={classes.header}>
          <strong>{title}</strong>
        </Typography>
        {children}
      </div>
    </Dialog>
  );
};

export default RequestDialog;
