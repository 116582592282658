import NumberFormat from "react-number-format";
import { Field, FieldProps } from "formik";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
      mask="_"
      type="tel"
    />
  );
}

const FormPhoneField: React.FC<{
  name: string;
  margin?: BaseTextFieldProps["margin"];
}> = ({ name, margin = "normal" }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = Boolean(meta.touched && meta.error);
        return (
          <TextField
            {...field}
            variant="outlined"
            label="Phone Number"
            margin={margin}
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            error={hasError}
            helperText={hasError ? meta.error : "Optional"}
          />
        );
      }}
    </Field>
  );
};

export default FormPhoneField;
