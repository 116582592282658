import React from "react";
import { REQUESTS } from "quipquotes-commons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useFormDialog } from "../FormHelpers/FormDialog";
import firebase from "../../firebase";
import { AdminRequest } from "./RequestForm";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `rgba(0, 0, 0, 0.5)`,
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    zIndex: 99999,
  },
}));

const DealerContactedSwitch: React.FC<{
  requestId: string;
  dealerHasBeenContacted: boolean;
}> = ({ requestId, dealerHasBeenContacted }) => {
  const classes = useStyles();
  const [contacted, setContacted] = React.useState(
    () => dealerHasBeenContacted
  );
  const [loading, setLoading] = React.useState(false);
  const { updateEditValues, editValues } = useFormDialog();
  React.useEffect(() => {
    setContacted(dealerHasBeenContacted);
  }, [dealerHasBeenContacted]);
  const handleChange = async () => {
    setLoading(true);
    const newValue = !dealerHasBeenContacted;
    setContacted(newValue);
    updateEditValues({
      ...editValues,
      requests: editValues.requests.map((request: AdminRequest) => {
        if (request.id === requestId) {
          return {
            ...request,
            dealerHasBeenContacted: newValue,
          };
        }
        return request;
      }),
    });
    await firebase
      .firestore()
      .collection(REQUESTS)
      .doc(requestId)
      .update({ dealerHasBeenContacted: newValue });
    setLoading(false);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={contacted}
            onChange={handleChange}
            name="dealerHasBeenContacted"
            color="primary"
          />
        }
        label={"Dealer has been contacted"}
      />
      {loading && (
        <div className={classes.loadingContainer}>
          <Typography>Wait one sec...</Typography>
        </div>
      )}
    </>
  );
};

export default DealerContactedSwitch;
