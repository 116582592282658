import React from "react";
import Button from "@material-ui/core/Button";
import { convertCentsToCurrency, Quote, Request } from "quipquotes-commons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import RequestDialog from "./RequestDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -theme.spacing(2),
    "& .quote": {
      padding: `${theme.spacing(2)}px 0`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    "& .quote:last-child": {
      borderBottom: 0,
    },
    " & .data-item": {
      marginBottom: theme.spacing(1),
    },
  },
}));

const DataItem: React.FC<{
  label: string;
  value: React.ReactNode;
  className?: string;
}> = ({ label, value, className }) => {
  return (
    <div className={className}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography>
          <strong>{value}</strong>
        </Typography>
      ) : (
        value
      )}
    </div>
  );
};

const PriceTable: React.FC<{
  priceItems: Quote["priceItems"];
  type: Quote["type"];
}> = ({ priceItems, type }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Description</strong>
          </TableCell>
          <TableCell>
            <strong>Price{type === "rental" ? " per month" : ""}</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {priceItems.map((item) => {
          return (
            <TableRow key={item.description}>
              <TableCell>{item.description}</TableCell>
              <TableCell>{convertCentsToCurrency(item.price)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const ViewQuotesModal: React.FC<{
  quotes: Request["quotes"];
}> = ({ quotes }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        startIcon={<ListIcon />}
        disabled={quotes.length === 0}
        onClick={handleOpen}
      >
        View Quotes ({quotes.length})
      </Button>
      <RequestDialog open={open} handleClose={handleClose} title="View Quotes">
        <div className={classes.root}>
          {quotes.map((quote, index) => {
            return (
              <div key={`quote-${index}`} className="quote">
                <Typography variant="body2" gutterBottom color="secondary">
                  <strong>Quote #{index + 1}</strong>
                </Typography>
                <DataItem
                  label="Type"
                  value={
                    quote.type.slice(0, 1).toUpperCase() + quote.type.slice(1)
                  }
                  className="data-item"
                />
                <DataItem
                  label="Price Line Items"
                  value={
                    <PriceTable
                      priceItems={quote.priceItems}
                      type={quote.type}
                    />
                  }
                  className="data-item"
                />
                {quote.notes && (
                  <DataItem
                    label="Notes"
                    value={
                      <div dangerouslySetInnerHTML={{ __html: quote.notes }} />
                    }
                    className="data-item"
                  />
                )}
                <DataItem
                  label="Total"
                  className="data-item"
                  value={
                    <Typography variant="h5">
                      <strong>
                        {convertCentsToCurrency(quote.totalPrice)}
                        {quote.type === "rental" && <small> per month</small>}
                      </strong>
                    </Typography>
                  }
                />
                <DataItem label="Status" value={quote.status} />
              </div>
            );
          })}
        </div>
      </RequestDialog>
    </>
  );
};

export default ViewQuotesModal;
