import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import FormRichTextField from "../FormHelpers/FormRichTextField";
import FormTextField from "../FormHelpers/FormTextField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import FormImageField from "../FormHelpers/FormImageField";
import { useFormDialog } from "../FormHelpers/FormDialog";
import {
  generateInitialValues,
  generateSubmitValues,
  handleError,
} from "../../utils";
import { InputField } from "../../constants";
import ManufacturerFormPreview from "./ManufacturerFormPreview";
import firebase from "../../firebase";
import { useSnackbar } from "../Snackbar";
import { useContentManagementContext } from "../ContentManagementContext";
import FormSwitchField from "../FormHelpers/FormSwitchField";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    "& > div": {
      flexBasis: "50%",
      maxWidth: "50%",
      minHeight: "calc(100vh - 64px)",
      padding: theme.spacing(2),
    },
    "& > div:first-child": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

const fields: InputField[] = [
  { name: "name" },
  { name: "description" },
  {
    name: "logoImage",
    imageField: "default",
  },
  {
    name: "bannerImage",
    imageField: "default",
  },
  { name: "enabled", initialValue: true },
];

const ManufacturerForm: React.FC<{
  handleSubmit: (data: any, deleteId?: string) => void;
}> = ({ handleSubmit }) => {
  const classes = useStyles();
  const { editValues, openDialog } = useFormDialog();
  const initialValues = generateInitialValues(fields, editValues);
  const { setSnackbarMessage } = useSnackbar();
  const { toggleUnsavedChanges } = useContentManagementContext();
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          if (!values.name) {
            errors.name = "Please enter a name";
          }
          if (!values.description) {
            errors.description = "Please enter a description";
          }
          if (!values.logoImage.imageURL && !values.logoImage.file) {
            errors.logoImage = "Please add a logo image";
          }
          if (!values.bannerImage.imageURL && !values.bannerImage.file) {
            errors.bannerImage = "Please add a banner image";
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            const actionName = editValues
              ? "updateManufacturer"
              : "createManufacturer";
            const action = firebase.functions().httpsCallable(actionName);
            const submitValues = await generateSubmitValues(
              values,
              fields,
              editValues,
              "manufacturer"
            );
            const { data } = await action(submitValues);
            actions.setSubmitting(false);
            openDialog(false);
            toggleUnsavedChanges(true);
            handleSubmit(
              data,
              editValues && data.id !== editValues.id
                ? editValues.id
                : undefined
            );
            setSnackbarMessage(
              `Manufacturer ${editValues ? "updated" : "created"} successfully`
            );
          } catch (err) {
            handleError(err);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <div className={classes.grid}>
              <div>
                <Form>
                  <FormTextField name="name" label="Manufacturer Name" />
                  <FormRichTextField name="description" label="Description" />
                  <FormSwitchField
                    name="enabled"
                    label="Manufacturer Enabled?"
                  />
                  <FormImageField name="logoImage" label="Logo Image" />
                  <FormImageField name="bannerImage" label="Banner Image" />
                  <FormSubmitButton isSubmitting={isSubmitting} />
                </Form>
              </div>
              <div>
                <ManufacturerFormPreview
                  logoImageInputValues={values.logoImage}
                  bannerImageInputValues={values.bannerImage}
                  name={values.name}
                  description={values.description}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ManufacturerForm;
