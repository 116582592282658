import React from "react";
import { Formik, Form } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import firebase from "../../firebase";
import Button from "@material-ui/core/Button";
import { REQUESTS, RequestStatuses } from "quipquotes-commons";
import Typography from "@material-ui/core/Typography";
import RequestDialog from "./RequestDialog";
import FormSelectField from "../FormHelpers/FormSelectField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { AdminRequest } from "./RequestForm";
import { useSnackbar } from "../Snackbar";

const options = Object.values(RequestStatuses).map((key) => ({
  value: key,
  label: key,
}));

const UpdateRequestStatusModal: React.FC<{
  requestId: string;
  requestStatus: RequestStatuses;
}> = ({ requestId, requestStatus }) => {
  const { updateEditValues, editValues } = useFormDialog();
  const { setSnackbarMessage } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
        startIcon={<EditIcon />}
      >
        Edit Status
      </Button>
      <RequestDialog
        open={open}
        handleClose={handleClose}
        title="Edit Request Status"
      >
        <div>
          <Typography gutterBottom color="error">
            This will permanently update the status, so be careful!
          </Typography>
          <Formik
            initialValues={{ requestStatus }}
            onSubmit={async (values) => {
              try {
                await firebase
                  .firestore()
                  .collection(REQUESTS)
                  .doc(requestId)
                  .update({ status: values.requestStatus });
                updateEditValues({
                  ...editValues,
                  requests: editValues.requests.map((request: AdminRequest) => {
                    if (request.id === requestId) {
                      return {
                        ...request,
                        status: values.requestStatus,
                      };
                    }
                    return request;
                  }),
                });
                setSnackbarMessage("Request status updated");
                handleClose();
              } catch (err: any) {
                window.alert("An error occurred - " + err.message);
              }
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <FormSelectField
                    label="Request Status"
                    name="requestStatus"
                    options={options}
                  />
                  <FormSubmitButton isSubmitting={isSubmitting} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </RequestDialog>
    </>
  );
};

export default UpdateRequestStatusModal;
