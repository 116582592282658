import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { MANUFACTURERS } from "quipquotes-commons/dist/constants";
import ManufacturersForm from "./ManufacturerForm";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";
import { replaceParagraphRegEx } from "../../utils";
import { fetchManufacturers } from "../../utils/dataFetchers";
import {
  SET_LOADING,
  UPDATE_ONE,
  UPDATE_ONE_BY_ID,
} from "../../constants/actions";

const PageComponent: React.FC<{
  loading: boolean;
  data: any[];
  handleRefresh: () => void;
}> = ({ loading, data, handleRefresh }) => {
  const { openDialog, updateEditValues } = useFormDialog();
  function addClick() {
    updateEditValues(null);
    openDialog(true);
  }
  const SearchbarButtons = (
    <div>
      <Button variant="contained" color="primary" onClick={addClick}>
        ADD MANUFACTURER
      </Button>
    </div>
  );
  return (
    <VirtualizedTable
      handleRefresh={handleRefresh}
      tableWidth={850}
      searchPlaceholder="Search by manufacturer name"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "name",
          width: 250,
          label: "Name",
        },
        {
          dataKey: "description",
          width: 400,
          label: "Description",
          cellRenderer: ({ cellData }) => {
            return cellData.replace(replaceParagraphRegEx, "");
          },
        },
        {
          dataKey: "enabled",
          width: 150,
          label: "Enabled?",
          cellRenderer: ({ cellData }) => (cellData ? "Yes" : "No"),
          isNotString: true,
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues(rowData);
              openDialog(true);
            };
            return (
              <Tooltip title="Edit">
                <span>
                  <IconButton onClick={handleClick}>
                    <EditIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
      SearchbarButtons={SearchbarButtons}
    />
  );
};

const ManufacturerPage: React.FC = () => {
  const {
    state: {
      data: { manufacturers },
      metadata: { loading },
    },
    dispatch,
  } = useStore();
  const handleRefresh = async () => {
    dispatch({ type: SET_LOADING });
    dispatch({
      type: UPDATE_ONE,
      payload: { key: MANUFACTURERS, data: await fetchManufacturers() },
    });
  };
  const handleSubmit = (data: any, deleteId?: string) => {
    dispatch({
      type: UPDATE_ONE_BY_ID,
      payload: { key: MANUFACTURERS, id: data.id, data, deleteId },
    });
  };
  return (
    <FormDialogProvider
      formLabel="Manufacturer"
      FormComponent={<ManufacturersForm handleSubmit={handleSubmit} />}
      fullScreen
    >
      <PageComponent
        data={manufacturers}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default ManufacturerPage;
