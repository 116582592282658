import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FieldArray } from "formik";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import FormTextField from "../FormHelpers/FormTextField";
import FormSelectField from "../FormHelpers/FormSelectField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import FormImageField from "../FormHelpers/FormImageField";
import FormRichTextField from "../FormHelpers/FormRichTextField";
import { useFormDialog } from "../FormHelpers/FormDialog";
import {
  generateInitialValues,
  generateSubmitValues,
  handleError,
  processImage,
} from "../../utils";
import { ImageInputValues, InputField } from "../../constants";
import firebase from "../../firebase";
import { useSnackbar } from "../Snackbar";
import { useContentManagementContext } from "../ContentManagementContext";
import BlogFormPreview from "./BlogFormPreview";
import { useStore } from "../Store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  grid: {
    display: "flex",
    "& > div": {
      flexBasis: "50%",
      maxWidth: "50%",
      minHeight: "calc(100vh - 64px)",
      padding: theme.spacing(2),
    },
    "& > div:first-child": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  blogElement: {
    padding: `${theme.spacing(1)}px 0`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const blogTypeOptions = [
  { label: "Text", value: "text" },
  { label: "Image", value: "image" },
];

export type BlogElement =
  | { type: "text"; value: string }
  | { type: "image"; value: ImageInputValues };

const fields: InputField[] = [
  { name: "title" },
  { name: "description" },
  { name: "headerImage", imageField: "aspectRatioAndCaption" },
  {
    name: "elements",
    initialValue: [],
    formatFunction: (elements) => {
      return elements.map((element: BlogElement) => {
        if (element.type === "text") {
          return element;
        }
        return {
          type: element.type,
          value: {
            ...element.value,
            blob: "",
            file: null,
          },
        };
      });
    },
  },
  { name: "featuredMachines", initialValue: [] },
];

const validate = (values: any) => {
  if (!values.title) {
    return "please include a title";
  }
  if (!values.description) {
    return "please include a description";
  }
  if (!values.headerImage.imageURL && !values.headerImage.file) {
    return "please upload a header image";
  }
  if (!values.headerImage.aspectRatio || !values.headerImage.caption) {
    return "header image must have aspect ratio and caption";
  }
  if (values.elements.length > 0) {
    for (const [index, element] of values.elements.entries()) {
      if (!element.type) {
        return `all elements must have a type - check element #${index + 1}`;
      } else if (element.type === "text" && !element.value) {
        return `all text elements must have a value - check element #${
          index + 1
        }`;
      } else if (element.type === "image") {
        if (!element.value.imageURL && !element.value.file) {
          return `please upload an image for all image elements - check element #${
            index + 1
          }`;
        }
        if (!element.value.aspectRatio || !element.value.caption) {
          return `please include an aspect ratio and caption for all image elements - check element #${
            index + 1
          }`;
        }
      }
    }
  } else {
    return "blog must have at least one element";
  }
  return null;
};

const BlogForm: React.FC<{ handleSubmit: (data: any) => void }> = ({
  handleSubmit,
}) => {
  const { editValues, openDialog } = useFormDialog();
  const initialValues = generateInitialValues(fields, editValues);
  const { setSnackbarMessage } = useSnackbar();
  const { toggleUnsavedChanges } = useContentManagementContext();
  const classes = useStyles();
  const store = useStore();
  const featuredMachineOptions = React.useMemo(() => {
    return store.state.data.machines
      .map((machine) => {
        return {
          value: machine.id,
          label: `${machine.manufacturer} ${machine.name}`,
        };
      })
      .sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return -1;
      });
  }, [store]);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const validationError = validate(values);
            if (validationError) {
              throw new Error(validationError);
            }
            const actionName = editValues ? "updateBlog" : "createBlog";
            const action = firebase.functions().httpsCallable(actionName);
            const submitValues = await generateSubmitValues(
              values,
              fields,
              editValues,
              "blog"
            );
            const elements = editValues
              ? submitValues.data.elements
              : submitValues.elements;
            for (const element of elements) {
              if (element.type === "image") {
                element.value = await processImage(element.value, "blog");
              }
            }
            const { data } = await action(submitValues);
            actions.setSubmitting(false);
            openDialog(false);
            toggleUnsavedChanges(true);
            handleSubmit(data);
            setSnackbarMessage(
              `Blog ${editValues ? "updated" : "created"} successfully`
            );
          } catch (err) {
            handleError(err);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <div className={classes.grid}>
                <div>
                  <FormTextField
                    name="title"
                    label="Title"
                    disabled={Boolean(editValues)}
                  />
                  <FormTextField
                    name="description"
                    label="Description"
                    helperText="This will appear as the first paragraph in the blog and will also show up when sharing links to the blog"
                    rows={3}
                    multiline
                  />
                  <FormImageField
                    name="headerImage"
                    label="Header Image"
                    addAspectRatioAndCaption
                  />
                  <FieldArray
                    validateOnChange={false}
                    name="elements"
                    render={({ push, remove, replace }) => {
                      const handleAdd = () => {
                        push({
                          type: "",
                        });
                      };
                      return (
                        <div>
                          <Typography
                            color="textSecondary"
                            className={classes.marginTop2}
                          >
                            Blog Elements
                          </Typography>
                          {values.elements.map(
                            (element: BlogElement, index: number) => {
                              const handleRemove = () => {
                                remove(index);
                              };
                              const handleSelect = (
                                e: React.ChangeEvent<any>
                              ) => {
                                replace(index, {
                                  type: e.target.value,
                                  value:
                                    e.target.value === "image"
                                      ? {
                                          file: null,
                                          blob: "",
                                          imageURL: "",
                                          fileName: "",
                                          aspectRatio: "",
                                          caption: "",
                                        }
                                      : "",
                                });
                              };
                              const renderField = () => {
                                if (element.type === "image") {
                                  return (
                                    <FormImageField
                                      name={`elements.${index}.value`}
                                      label={`Element #${index + 1} Image`}
                                      addAspectRatioAndCaption
                                    />
                                  );
                                } else if (element.type === "text") {
                                  return (
                                    <FormRichTextField
                                      name={`elements.${index}.value`}
                                      label={`Element #${index + 1} Text`}
                                      toolbarOptions={[
                                        "heading",
                                        "|",
                                        "link",
                                        "numberedList",
                                        "bulletedList",
                                      ]}
                                    />
                                  );
                                }
                                return null;
                              };
                              return (
                                <div
                                  key={`element-${index}`}
                                  className={classes.blogElement}
                                >
                                  <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <FormSelectField
                                        label={`Element #${index + 1} Type`}
                                        options={blogTypeOptions}
                                        name={`elements.${index}.type`}
                                        onChange={handleSelect}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleRemove}
                                      >
                                        Remove
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <div>{renderField()}</div>
                                </div>
                              );
                            }
                          )}
                          <div className={classes.marginTop2}>
                            <Button
                              size="small"
                              onClick={handleAdd}
                              color="primary"
                              variant="outlined"
                              startIcon={<AddIcon />}
                            >
                              ADD ELEMENT
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <div className={classes.marginTop}>
                    <FormSelectField
                      name="featuredMachines"
                      label="Featured Machines"
                      helperText="Optional - this will automatically add links to these machines at the bottom of the blog"
                      options={featuredMachineOptions}
                      multiple
                    />
                  </div>
                  <FormSubmitButton isSubmitting={isSubmitting} />
                </div>
                <div>
                  <BlogFormPreview
                    title={values.title}
                    description={values.description}
                    headerImage={values.headerImage}
                    elements={values.elements}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlogForm;
