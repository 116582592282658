import React from "react";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, FieldProps } from "formik";

interface FormSelectFieldProps {
  name: string;
  label: string;
  options: { value: any; label: string }[];
  margin?: BaseTextFieldProps["margin"];
  helperText?: string;
  onChange?: (event: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  multiple?: boolean;
  native?: boolean;
}

const FormSelectField: React.FC<FormSelectFieldProps> = ({
  name,
  label,
  options,
  helperText = "",
  margin = "normal",
  onChange,
  disabled,
  multiple,
  native,
}) => {
  const SelectOptions = native
    ? [
        <option key="blank-option" aria-label="None" value="" />,
        ...options.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        }),
      ]
    : options.map(({ value, label }) => {
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      });
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = Boolean(meta.touched && meta.error);
        return (
          <TextField
            {...field}
            label={label}
            onChange={onChange || field.onChange}
            helperText={hasError ? meta.error : helperText}
            variant="outlined"
            fullWidth
            error={hasError}
            margin={margin}
            select
            disabled={disabled}
            SelectProps={{ multiple, native }}
          >
            {SelectOptions}
          </TextField>
        );
      }}
    </Field>
  );
};

export default FormSelectField;
