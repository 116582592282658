import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { routes } from "../constants";
import { Link } from "react-router-dom";
import SaveBackupButton from "./SaveBackupButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 800,
    marginTop: theme.spacing(3),
    margin: "auto",
    "& .header": {
      marginBottom: theme.spacing(3),
      fontWeight: "bold",
    },
    "& .link": {
      textDecoration: "none",
      "& .paper": {
        padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "&:hover .paper": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
}));

export default function HomePage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        align="center"
        className="header"
        color="primary"
      >
        QuipQuotes Admin Home
      </Typography>
      <Grid container spacing={2}>
        {routes.map((route) => {
          return (
            <Grid item xs={6} key={route.label}>
              <Link to={route.route} className="link">
                <Paper className="paper" elevation={2}>
                  <Typography variant="h6">
                    <strong>{route.label}</strong>
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          );
        })}
      </Grid>
      <SaveBackupButton />
    </div>
  );
}
