import { DealerAccount } from "quipquotes-commons";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    textAlign: "left",
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: "4px 0",
    padding: "2px 8px",
    cursor: "pointer",
    transition: "all .2s",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
    "&.active": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
    },
    "& .item": {
      width: "33.333333%",
    },
    "& .caption": {
      opacity: 0.8,
    },
  },
}));

const DealerAccountButton: React.FC<{
  dealerAccount: DealerAccount & { id: string };
  onClick: () => void;
  active: boolean;
}> = ({ dealerAccount, onClick, active }) => {
  const classes = useStyles();
  return (
    <button
      onClick={onClick}
      className={`${classes.root} ${active ? "active" : ""}`}
    >
      <div className="item">
        <Typography variant="caption" className="caption">
          Email
        </Typography>
        <Typography variant="body2">
          <strong>{dealerAccount.email}</strong>
        </Typography>
      </div>
      <div className="item">
        <Typography variant="caption" className="caption">
          Name
        </Typography>
        <Typography variant="body2">
          {dealerAccount.name ? (
            <strong>{dealerAccount.name}</strong>
          ) : (
            <em>None provided</em>
          )}
        </Typography>
      </div>
      <div className="item">
        <Typography variant="caption" className="caption">
          Phone number
        </Typography>
        <Typography variant="body2">
          {dealerAccount.phone ? (
            <strong>{dealerAccount.phone}</strong>
          ) : (
            <em>None provided</em>
          )}
        </Typography>
      </div>
    </button>
  );
};

export default DealerAccountButton;
