import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FireTypes from "firebase";
import { Formik, Form, FieldArray } from "formik";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormTextField from "../FormHelpers/FormTextField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import { useFormDialog } from "../FormHelpers/FormDialog";
import {
  generateInitialValues,
  generateSubmitValues,
  handleError,
} from "../../utils";
import { InputField } from "../../constants";
import firebase from "../../firebase";
import { useSnackbar } from "../Snackbar";
import { useContentManagementContext } from "../ContentManagementContext";
import LoadingIcon from "../LoadingIcon";
import LocationFormFields from "../Location/LocationFormFields";
import DealerAccountsDialog from "./DealerAccountsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },
  header: {
    marginTop: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  removeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: `${theme.spacing(1)}px 0`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  submitContainer: {
    width: 250,
  },
  dealerContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const locationKeys = ["locationName", "address", "city", "state", "zip"];
const initLocation = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  manufacturers: [],
  email: "",
  phone: "",
  contactName: "",
};

const fields: InputField[] = [
  { name: "name" },
  { name: "locations", initialValue: [initLocation] },
];

const DealerForm: React.FC<{
  handleSubmit: (data: any) => void;
  authUser: FireTypes.User | null;
}> = ({ handleSubmit, authUser }) => {
  const [view, setView] = React.useState("Grid view");
  const [fetchingData, setFetchingData] = React.useState(true);
  const { editValues, openDialog, updateEditValues } = useFormDialog();
  const initialValues = generateInitialValues(fields, editValues);
  const { setSnackbarMessage } = useSnackbar();
  const { toggleUnsavedChanges } = useContentManagementContext();
  const classes = useStyles();
  const fetchDealer = React.useCallback(async () => {
    try {
      const getDealer = firebase.functions().httpsCallable("getDealer");
      const { data } = await getDealer({ id: editValues.id });
      updateEditValues({
        id: data.dealer.id,
        name: data.dealer.name,
        locations: data.locations,
        dealerAccounts: data.dealerAccounts,
      });
      setFetchingData(false);
    } catch (err) {
      handleError(err);
    }
  }, [editValues, updateEditValues]);
  React.useEffect(() => {
    if (editValues && editValues.fetchDealerData) {
      fetchDealer();
    } else {
      setFetchingData(false);
    }
  }, [editValues, fetchDealer]);
  if (fetchingData) {
    return <LoadingIcon text="Loading dealer data..." />;
  }
  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          if (!values.name) {
            errors.name = "Please enter a dealer name";
          }
          for (const [index, location] of values.locations.entries()) {
            for (const key of locationKeys) {
              if (!location[key]) {
                errors.locations = `Each location must have a ${key} (check location #${
                  index + 1
                })`;
                break;
              }
            }
            if (/\b\d{5}\b/.test(location.zip) === false) {
              errors.locations = `Location zip code is invalid (check location #${
                index + 1
              })`;
            }
            if (location.manufacturers.length === 0) {
              errors.locations = `Each location must have at least one manufacturer (check location #${
                index + 1
              })`;
            }
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            const actionName = editValues ? "updateDealer" : "createDealer";
            const action = firebase.functions().httpsCallable(actionName);
            const submitValues = await generateSubmitValues(
              values,
              fields,
              editValues,
              "dealer"
            );
            const { data } = await action(submitValues);
            actions.setSubmitting(false);
            openDialog(false);
            toggleUnsavedChanges(true);
            handleSubmit(data);
            setSnackbarMessage(
              `Dealer ${editValues ? "updated" : "created"} successfully`
            );
          } catch (err) {
            handleError(err);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, submitCount, errors }) => {
          return (
            <Form>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                className={classes.dealerContainer}
              >
                <Grid item xs>
                  <FormTextField name="name" label="Dealer Name" />
                </Grid>
                {editValues && (
                  <Grid item>
                    <div>
                      <DealerAccountsDialog
                        dealerAccounts={editValues.dealerAccounts}
                        dealerId={editValues.id}
                        dealerName={editValues.name}
                        authUser={authUser}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <FieldArray
                validateOnChange={false}
                name="locations"
                render={({ push, remove }) => {
                  const handleAdd = () => {
                    push(initLocation);
                  };
                  return (
                    <div>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.header}
                      >
                        <Grid item>
                          <Typography
                            color={
                              submitCount > 0 &&
                              typeof errors.locations === "string"
                                ? "error"
                                : "textSecondary"
                            }
                          >
                            <strong>Locations</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            {["Grid view", "Stacked view"].map((viewValue) => {
                              const handleClick = () => {
                                setView(viewValue);
                              };
                              const active = viewValue === view;
                              return (
                                <Grid item>
                                  <Button
                                    onClick={handleClick}
                                    variant={active ? "contained" : "outlined"}
                                    color={active ? "primary" : "default"}
                                    size="small"
                                  >
                                    {viewValue}
                                  </Button>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={view === "Stacked view" ? 0 : 2}>
                        {values.locations.map(
                          (location: any, index: number) => {
                            const handleRemove = () => {
                              const res = window.confirm(
                                "Remove this location?"
                              );
                              if (res) {
                                remove(index);
                              }
                            };
                            return (
                              <Grid
                                item
                                xs={view === "Stacked view" ? 12 : 6}
                                key={`location-${index}`}
                                className={
                                  index % 2 === 0 ? classes.borderRight : ""
                                }
                              >
                                <LocationFormFields
                                  index={index}
                                  handleRemove={
                                    values.locations.length > 1
                                      ? handleRemove
                                      : undefined
                                  }
                                  locationValues={location}
                                  gridView={view === "Grid view"}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                      {submitCount > 0 && typeof errors.locations === "string" && (
                        <div className={classes.marginTop}>
                          <Typography variant="caption" color="error">
                            {errors.locations}
                          </Typography>
                        </div>
                      )}
                      <div className={classes.margin}>
                        <Button
                          size="small"
                          onClick={handleAdd}
                          color="primary"
                          variant="outlined"
                          startIcon={<AddIcon />}
                        >
                          ADD LOCATION
                        </Button>
                      </div>
                    </div>
                  );
                }}
              />
              <div className={classes.submitContainer}>
                <FormSubmitButton isSubmitting={isSubmitting} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DealerForm;
