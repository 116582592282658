import React from "react";
import FireTypes from "firebase";
import { getSuperAgentErrorMessage } from "quipquotes-commons";
import * as superagent from "superagent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import RequestDialog from "./RequestDialog";
import { API_ENDPOINT } from "../../config";
import { setAuthHeaders } from "../../utils";
import LoadingIcon from "../LoadingIcon";
import { useSnackbar } from "../Snackbar";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { AdminRequest } from "./RequestForm";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const EmailPreviewModal: React.FC<{
  parentRequestId: string;
  requestId: string;
  dealerAccountId: string;
  authUser: FireTypes.User | null;
  dealerHasBeenContacted: boolean;
}> = ({
  parentRequestId,
  requestId,
  dealerAccountId,
  authUser,
  dealerHasBeenContacted,
}) => {
  const [showWarning, setShowWarning] = React.useState(dealerHasBeenContacted);
  const classes = useStyles();
  const [html, setHtml] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { setSnackbarMessage } = useSnackbar();
  const { editValues, updateEditValues } = useFormDialog();

  const handleClose = () => {
    setOpen(false);
    setShowWarning(dealerHasBeenContacted);
  };
  const hideWarning = () => {
    setShowWarning(false);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await superagent
        .post(`${API_ENDPOINT}/admin/send-dealer-email`)
        .send({ parentRequestId, requestId, dealerAccountId })
        .set(await setAuthHeaders(authUser));
      updateEditValues({
        ...editValues,
        requests: editValues.requests.map((request: AdminRequest) => {
          if (request.id === requestId) {
            return {
              ...request,
              dealerHasBeenContacted: true,
            };
          }
          return request;
        }),
      });
      setShowWarning(true);
      setSubmitting(false);
      setSnackbarMessage("Email sent to dealer");
      setOpen(false);
    } catch (err) {
      window.alert(getSuperAgentErrorMessage(err));
      setSubmitting(false);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (open && !showWarning) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const { body } = await superagent
            .get(
              `${API_ENDPOINT}/admin/dealer-email-preview/${parentRequestId}/${requestId}/${dealerAccountId}`
            )
            .set(await setAuthHeaders(authUser));
          setHtml(body.html);
          setLoading(false);
        } catch (err) {
          window.alert(getSuperAgentErrorMessage(err));
          setLoading(false);
          setOpen(false);
        }
      };
      fetchData();
    }
  }, [
    open,
    authUser,
    parentRequestId,
    requestId,
    dealerAccountId,
    showWarning,
  ]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        startIcon={<EmailIcon />}
        disabled={!dealerAccountId}
        onClick={handleOpen}
      >
        Email Dealer
      </Button>
      <RequestDialog
        open={open}
        handleClose={handleClose}
        title="Preview Dealer Email"
      >
        <div>
          {loading ? (
            <LoadingIcon text="Loading email preview..." />
          ) : showWarning ? (
            <div>
              <Typography>
                This dealer has already been contacted. Are you sure you want to
                send another email?
              </Typography>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button onClick={handleClose}>No</Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={hideWarning}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className={classes.container}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              <div className={`${classes.container} ${classes.marginBottom}`}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  SEND THIS EMAIL TO DEALER
                </Button>
              </div>
            </div>
          )}
        </div>
      </RequestDialog>
    </>
  );
};

export default EmailPreviewModal;
