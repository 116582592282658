import React from "react";
import moment from "moment";
import FireTypes from "firebase";
import { PARENT_REQUESTS } from "quipquotes-commons";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import { green, red } from "@material-ui/core/colors";
import RequestForm from "./RequestForm";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";
import useMachineOptions from "../../utils/useMachineOptions";
import {
  SET_LOADING,
  UPDATE_NEW_REQUEST_COUNT,
  UPDATE_ONE,
} from "../../constants/actions";
import { fetchParentRequests } from "../../utils/dataFetchers";
import { getNewRequestCount } from "../../utils";

const PageComponent: React.FC<{
  data: any[];
  loading: boolean;
  handleRefresh: () => void;
}> = ({ data, loading, handleRefresh }) => {
  const { openDialog, updateEditValues } = useFormDialog();
  const { productFamilyOptions } = useMachineOptions();
  return (
    <VirtualizedTable
      filters={[
        {
          label: "Product Family",
          dataKey: "productFamily",
          options: productFamilyOptions,
        },
        {
          label: "All Requests Completed",
          dataKey: "allRequestsCompleted",
          options: [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ],
        },
      ]}
      tableWidth={1400}
      searchPlaceholder="Search by name, email or product family"
      loading={loading}
      data={data}
      handleRefresh={handleRefresh}
      columns={[
        {
          dataKey: "productFamily",
          width: 150,
          label: "Product Family",
        },
        {
          dataKey: "createdAt",
          width: 150,
          label: "Date Submitted",
          cellRenderer: ({ cellData }) => moment(cellData).format("MM/DD/YYYY"),
          isNotString: true,
        },
        {
          dataKey: "customerName",
          width: 150,
          label: "Customer Name",
        },
        {
          dataKey: "customerEmail",
          width: 170,
          label: "Customer Email",
        },
        {
          dataKey: "deliveryLocation",
          width: 300,
          label: "Delivery Location",
        },
        {
          dataKey: "completedRequests",
          width: 150,
          label: "Total Completed",
          isNotString: true,
        },
        {
          dataKey: "totalRequests",
          width: 150,
          label: "Total Requests",
          isNotString: true,
        },
        {
          dataKey: "allRequestsCompleted",
          width: 200,
          label: "All Completed?",
          cellRenderer: ({ cellData }) =>
            cellData === "Yes" ? (
              <span style={{ color: green[600], fontWeight: "bold" }}>Yes</span>
            ) : (
              <span style={{ color: red[600], fontWeight: "bold" }}>No</span>
            ),
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues({ id: rowData.id, fetchRequestData: true });
              openDialog(true);
            };
            return (
              <Tooltip title="View">
                <span>
                  <IconButton onClick={handleClick}>
                    <LaunchIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
    />
  );
};

const RequestPage: React.FC<{ authUser: FireTypes.User | null }> = ({
  authUser,
}) => {
  const {
    state: {
      data: { parentRequests },
      metadata: { loading },
    },
    dispatch,
  } = useStore();
  const handleRefresh = async () => {
    dispatch({ type: SET_LOADING });
    const data = await fetchParentRequests();
    dispatch({
      type: UPDATE_ONE,
      payload: { key: PARENT_REQUESTS, data },
    });
    dispatch({
      type: UPDATE_NEW_REQUEST_COUNT,
      payload: { newRequestCount: getNewRequestCount(data) },
    });
  };

  return (
    <FormDialogProvider
      formLabel="Request"
      FormComponent={<RequestForm authUser={authUser} />}
      maxWidth="md"
      editLabel="View"
    >
      <PageComponent
        data={parentRequests}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default RequestPage;
