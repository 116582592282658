import React from "react";
import {
  UPDATE_ALL,
  Action,
  UPDATE_ONE,
  SET_LOADING,
  UPDATE_ONE_BY_ID,
  DELETE_ONE_BY_ID,
  UPDATE_NEW_REQUEST_COUNT,
} from "../constants/actions";

export interface State {
  data: {
    manufacturers: any[];
    machines: any[];
    productFamilies: any[];
    dealers: any[];
    locations: any[];
    parentRequests: any[];
    blogs: any[];
  };
  metadata: {
    newRequestCount: number;
    loading: boolean;
  };
}
const initStore: State = {
  data: {
    manufacturers: [],
    machines: [],
    dealers: [],
    parentRequests: [],
    productFamilies: [],
    locations: [],
    blogs: [],
  },
  metadata: {
    newRequestCount: 0,
    loading: false,
  },
};

const StoreContext = React.createContext<[State, React.Dispatch<Action>]>([
  initStore,
  () => {},
]);

const reducer = (state: State, action: Action): State => {
  if (action.type === UPDATE_ALL) {
    return {
      data: {
        ...state.data,
        ...action.payload.data,
      },
      metadata: {
        ...state.metadata,
        ...action.payload.metadata,
      },
    };
  } else if (action.type === UPDATE_ONE) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.key]: action.payload.data,
      },
      metadata: {
        ...state.metadata,
        loading: false,
      },
    };
  } else if (action.type === UPDATE_ONE_BY_ID) {
    const currentArray = [...state.data[action.payload.key]];
    const currentIndex = currentArray.findIndex(
      (item) => item.id === action.payload.id
    );
    const dataObj = { id: action.payload.id, ...action.payload.data };
    if (currentIndex === -1) {
      currentArray.unshift(dataObj);
    } else {
      currentArray[currentIndex] = dataObj;
    }
    if (action.payload.deleteId) {
      const deleteIndex = currentArray.findIndex(
        (item) => item.id === action.payload.deleteId
      );
      currentArray.splice(deleteIndex, 1);
    }
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.key]: currentArray,
      },
    };
  } else if (action.type === SET_LOADING) {
    return {
      ...state,
      metadata: {
        ...state.metadata,
        loading: true,
      },
    };
  } else if (action.type === DELETE_ONE_BY_ID) {
    const currentArray = [...state.data[action.payload.key]];
    const deleteIndex = currentArray.findIndex(
      (item) => item.id === action.payload.id
    );
    currentArray.splice(deleteIndex, 1);
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.key]: currentArray,
      },
    };
  } else if (action.type === UPDATE_NEW_REQUEST_COUNT) {
    return {
      ...state,
      metadata: {
        ...state.metadata,
        newRequestCount: action.payload.newRequestCount,
      },
    };
  }
  return state;
};

const StoreProvider: React.FC = ({ children }) => {
  const [store, dispatch] = React.useReducer(reducer, initStore);
  const value = React.useMemo<[State, React.Dispatch<Action>]>(() => {
    return [store, dispatch];
  }, [store, dispatch]);
  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

const useStore = () => {
  const [state, dispatch] = React.useContext(StoreContext);
  const storeMaps = {
    productFamilies: state.data.productFamilies.reduce((a, b) => {
      a[b.id] = b;
      return a;
    }, {}),
  };
  return { state, dispatch, storeMaps };
};

export { StoreProvider, useStore };
