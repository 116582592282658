export const routes = [
  { label: "Requests", route: "/requests", showCount: true },
  { label: "Product Families", route: "/product-families" },
  { label: "Manufacturers", route: "/manufacturers" },
  { label: "Machines", route: "/machines" },
  { label: "Dealers", route: "/dealers" },
  { label: "Locations", route: "/locations" },
  { label: "Blogs", route: "/blogs" },
];

export interface InputField {
  name: string;
  initialValue?: any;
  formatFunction?: (value: any) => any;
  imageField?: "default" | "aspectRatioAndCaption";
  imageArray?: boolean;
}

export interface ImageInputValues {
  file: File | null;
  blob: string;
  imageURL: string;
  fileName?: string;
  aspectRatio?: string;
  caption?: string;
}
