import React from "react";
import NumberFormat from "react-number-format";
import { Field, FieldProps } from "formik";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalScale: number;
}

const generateNumberFormatComponent = (
  decimalScale: number,
  maxLength?: number,
  thousandSeparator?: boolean
) => {
  return function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        decimalScale={decimalScale}
        maxLength={maxLength}
        thousandSeparator={thousandSeparator}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  };
};

const FormPriceInput: React.FC<{
  name: string;
  label: string;
  decimalScale: number;
  maxLength?: number;
  thousandSeparator?: boolean;
  adornmentText?: string;
  disabled?: boolean;
}> = ({
  name,
  label,
  adornmentText,
  disabled,
  decimalScale,
  maxLength,
  thousandSeparator,
}) => {
  const inputComponent = React.useMemo(() => {
    return generateNumberFormatComponent(
      decimalScale,
      maxLength,
      thousandSeparator
    );
  }, [decimalScale, maxLength, thousandSeparator]) as any;
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = Boolean(meta.touched && meta.error);
        return (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            label={label}
            InputProps={{
              inputComponent,
              endAdornment: adornmentText ? (
                <InputAdornment position="end">{adornmentText}</InputAdornment>
              ) : undefined,
            }}
            fullWidth
            error={hasError}
            helperText={hasError ? meta.error : ""}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
};

export default FormPriceInput;
