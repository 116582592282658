import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { BLOGS } from "quipquotes-commons";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import BlogForm from "./BlogForm";
import firebase from "../../firebase";
import { UPDATE_ONE, UPDATE_ONE_BY_ID } from "../../constants/actions";
import { useStore } from "../Store";

const PageComponent: React.FC<{
  loading: boolean;
  data: any[];
  handleRefresh: () => void;
}> = ({ loading, data, handleRefresh }) => {
  const { openDialog, updateEditValues } = useFormDialog();
  function addClick() {
    updateEditValues(null);
    openDialog(true);
  }
  const SearchbarButtons = (
    <div>
      <Button variant="contained" color="primary" onClick={addClick}>
        ADD BLOG
      </Button>
    </div>
  );
  return (
    <VirtualizedTable
      handleRefresh={handleRefresh}
      tableWidth={650}
      searchPlaceholder="Search by blog title"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "title",
          width: 600,
          label: "Title",
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues(rowData);
              openDialog(true);
            };
            return (
              <Tooltip title="Edit">
                <span>
                  <IconButton onClick={handleClick}>
                    <EditIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
      SearchbarButtons={SearchbarButtons}
    />
  );
};

const BlogPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const {
    state: {
      data: { blogs },
    },
    dispatch,
  } = useStore();
  const handleRefresh = React.useCallback(async () => {
    setLoading(true);
    const data: any[] = [];
    const { docs } = await firebase.firestore().collection(BLOGS).get();
    docs.forEach((doc: any) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    dispatch({
      type: UPDATE_ONE,
      payload: { key: BLOGS, data },
    });
    setLoading(false);
  }, [dispatch]);

  const handleSubmit = (data: any, deleteId?: string) => {
    dispatch({
      type: UPDATE_ONE_BY_ID,
      payload: { key: BLOGS, id: data.id, data, deleteId },
    });
  };
  React.useEffect(() => {
    if (blogs.length === 0) {
      handleRefresh();
    }
    // eslint-disable-next-line
  }, [handleRefresh]);

  return (
    <FormDialogProvider
      formLabel="Blog"
      FormComponent={<BlogForm handleSubmit={handleSubmit} />}
      fullScreen
    >
      <PageComponent
        data={blogs}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default BlogPage;
