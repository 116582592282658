import Button from "@material-ui/core/Button";
import FireTypes from "firebase";
import UserIcon from "@material-ui/icons/AccountCircle";
import { Formik, Form } from "formik";
import { EMAIL_REG_EX, PARENT_REQUESTS } from "quipquotes-commons";
import React from "react";
import * as superagent from "superagent";
import RequestDialog from "./RequestDialog";
import FormTextField from "../FormHelpers/FormTextField";
import FormPhoneField from "../FormHelpers/FormPhoneField";
import { formatPhone, handleError, setAuthHeaders } from "../../utils";
import { useSnackbar } from "../Snackbar";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";
import { fetchParentRequests } from "../../utils/dataFetchers";
import { UPDATE_ONE } from "../../constants/actions";
import { API_ENDPOINT } from "../../config";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import { AdminRequest } from "./RequestForm";

const CreateDealerAccountModal: React.FC<{
  dealerName: string;
  dealerId: string;
  authUser: FireTypes.User | null;
  formLocation: "requests" | "dealers";
}> = ({ dealerId, dealerName, authUser, formLocation }) => {
  const { dispatch } = useStore();
  const { setSnackbarMessage } = useSnackbar();
  const { editValues, updateEditValues } = useFormDialog();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" startIcon={<UserIcon />} onClick={handleOpen}>
        Add Dealer Account
      </Button>
      <RequestDialog
        open={open}
        handleClose={handleClose}
        title={`${dealerName} - Add Account`}
      >
        <Formik
          initialValues={{
            email: "",
            name: "",
            phone: "",
          }}
          validate={(values) => {
            const errors: { [key: string]: string } = {};
            if (!EMAIL_REG_EX.test(values.email)) {
              errors.email = "Please enter a valid email";
            }
            if (values.phone && values.phone.length !== 10) {
              errors.phone = "Please enter a valid phone number";
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              const { body } = await superagent
                .post(`${API_ENDPOINT}/admin/create-dealer-account`)
                .send({
                  email: values.email,
                  phone: values.phone ? formatPhone(values.phone) : "",
                  name: values.name,
                  dealerId: dealerId,
                })
                .set(await setAuthHeaders(authUser));
              if (formLocation === "requests") {
                updateEditValues({
                  ...editValues,
                  requests: editValues.requests.map((request: AdminRequest) => {
                    if (request.location.dealerId === dealerId) {
                      return {
                        ...request,
                        dealerAccounts: [
                          ...request.dealerAccounts,
                          body.dealerAccount,
                        ],
                      };
                    }
                    return request;
                  }),
                });
              } else {
                updateEditValues({
                  ...editValues,
                  dealerAccounts: [
                    ...editValues.dealerAccounts,
                    body.dealerAccount,
                  ],
                });
              }
              const data = await fetchParentRequests();
              dispatch({
                type: UPDATE_ONE,
                payload: { key: PARENT_REQUESTS, data },
              });
              handleClose();
              setSnackbarMessage("Dealer account added successfully");
            } catch (err) {
              handleError(err);
              actions.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <FormTextField name="email" label="Email" type="email" />
                <FormTextField name="name" label="Name" helperText="Optional" />
                <FormPhoneField name="phone" />
                <FormSubmitButton isSubmitting={isSubmitting} />
              </Form>
            );
          }}
        </Formik>
      </RequestDialog>
    </>
  );
};

export default CreateDealerAccountModal;
