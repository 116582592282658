import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Typography from "@material-ui/core/Typography";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { generateInitialValues } from "../../utils";
import { InputField } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  header: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  ul: {
    marginTop: theme.spacing(1),
  },
}));

const fields: InputField[] = [{ name: "name" }, { name: "description" }];

const ProductFamilyForm: React.FC = () => {
  const { editValues } = useFormDialog();
  const initialValues = generateInitialValues(fields, editValues);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {}}
      >
        {({ values }) => {
          return (
            <Form>
              <Typography gutterBottom variant="h6" className={classes.header}>
                <strong>Name</strong>
              </Typography>
              <Typography>{editValues.id}</Typography>
              <Typography gutterBottom variant="h6" className={classes.header}>
                <strong>Description</strong>
              </Typography>
              <Typography>{values.description}</Typography>
              <Typography variant="h6" className={classes.header}>
                <strong>Default Configurations</strong>
              </Typography>
              <ul className={classes.ul}>
                {editValues.configurations.map(({ label, name, unit }: any) => {
                  return (
                    <li key={name}>
                      <Typography>
                        <strong>{label}</strong> {unit && `(${unit})`}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProductFamilyForm;
