import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FieldArray } from "formik";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { productFamilies } from "quipquotes-commons";
import FormRichTextField from "../FormHelpers/FormRichTextField";
import FormTextField from "../FormHelpers/FormTextField";
import FormNumberField from "../FormHelpers/FormNumberField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import FormDeleteButton from "../FormHelpers/FormDeleteButton";
import FormImageField from "../FormHelpers/FormImageField";
import { useFormDialog } from "../FormHelpers/FormDialog";
import {
  generateInitialValues,
  generateSubmitValues,
  handleError,
} from "../../utils";
import { InputField, ImageInputValues } from "../../constants";
import FormSelectField from "../FormHelpers/FormSelectField";
import MachineFormPreview from "./MachineFormPreview";
import firebase from "../../firebase";
import { useSnackbar } from "../Snackbar";
import { useContentManagementContext } from "../ContentManagementContext";
import useMachineOptions from "../../utils/useMachineOptions";
import FormSwitchField from "../FormHelpers/FormSwitchField";
import { useStore } from "../Store";
import { DELETE_ONE_BY_ID } from "../../constants/actions";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    "& > div": {
      flexBasis: "50%",
      maxWidth: "50%",
      minHeight: "calc(100vh - 64px)",
      padding: theme.spacing(2),
    },
    "& > div:first-child": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  marginTopDelete: {
    marginTop: theme.spacing(5),
  },
  generationContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: `${theme.spacing(1)}px 0`,
    marginTop: theme.spacing(1),
  },
}));

const fieldSet = new Set();
const configurationFields = [];
const productFamilyRequiredFields: { [key: string]: string[] } = {};
const productFamilyGenerationFields: { [key: string]: string[] } = {};
for (const name in productFamilies) {
  const { configurations } = productFamilies[name];
  productFamilyRequiredFields[name] = [];
  productFamilyGenerationFields[name] = [];
  for (const configuration of configurations) {
    productFamilyRequiredFields[name].push(configuration.name);
    if (configuration.type === "number") {
      productFamilyGenerationFields[name].push(configuration.name);
    }
    if (!fieldSet.has(configuration.name)) {
      if (configuration.type === "select") {
        configurationFields.push({ name: configuration.name });
      } else if (configuration.type === "number") {
        configurationFields.push({
          name: configuration.name,
          formatFunction: (value: any) => `${value}`,
        });
      }
      fieldSet.add(configuration.name);
    }
  }
}

const fields: InputField[] = [
  { name: "productFamily" },
  { name: "manufacturer" },
  { name: "name" },
  { name: "description" },
  { name: "useDefaultConfigurations", initialValue: true },
  { name: "additionalConfigurations", initialValue: [] },
  { name: "currentGeneration" },
  { name: "generations", initialValue: [] },
  {
    name: "images",
    imageArray: true,
  },
  {
    name: "ignoredDefaultFields", // this field is only for metadata purposes...it will not be stored in the database
    initialValue: {},
    formatFunction: () => ({}),
  },
  ...configurationFields,
];

const MachineForm: React.FC<{ handleSubmit: (data: any) => void }> = ({
  handleSubmit,
}) => {
  const classes = useStyles();
  const { editValues, openDialog } = useFormDialog();
  const { dispatch } = useStore();
  const initialValues = generateInitialValues(fields, editValues);
  const { setSnackbarMessage } = useSnackbar();
  const { toggleUnsavedChanges } = useContentManagementContext();
  const { manufacturerOptions, productFamilyOptions } = useMachineOptions();
  const handleDelete = editValues
    ? async () => {
        const deleteMachine = firebase
          .functions()
          .httpsCallable("deleteMachine");
        await deleteMachine({ id: editValues.id });
        dispatch({
          type: DELETE_ONE_BY_ID,
          payload: { id: editValues.id, key: "machines" },
        });
        toggleUnsavedChanges(true);
        openDialog(false);
      }
    : undefined;
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          if (!values.manufacturer) {
            errors.manufacturer = "Please select a manufacturer";
          }
          if (!values.name) {
            errors.name = "Please enter a machine name";
          }
          if (!values.productFamily) {
            errors.productFamily = "Please select a product family";
          } else {
            for (const field of productFamilyRequiredFields[
              values.productFamily
            ]) {
              if (!values[field] && !values.ignoredDefaultFields[field]) {
                errors[field] = "This is a required field";
              }
            }
          }
          if (!values.description) {
            errors.description = "Please enter a description";
          }

          for (const image of values.images) {
            if (!image.imageURL && !image.file) {
              errors.images = `Please add images for all image records`;
              break;
            }
          }
          for (const [index, generation] of values.generations.entries()) {
            for (const key in generation) {
              if (!generation[key]) {
                errors.generations = `Please ensure all generation values are populated -- check generation #${
                  index + 1
                }`;
                break;
              }
            }
          }
          for (const field of values.additionalConfigurations) {
            if (!field.label || !field.value) {
              errors.additionalConfigurations =
                "All additional configurations must have a label and a value";
            }
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            const actionName = editValues ? "updateMachine" : "createMachine";
            const action = firebase.functions().httpsCallable(actionName);
            const submitValues = await generateSubmitValues(
              values,
              fields,
              editValues,
              "machine"
            );
            const { data } = await action(submitValues);
            actions.setSubmitting(false);
            openDialog(false);
            toggleUnsavedChanges(true);
            handleSubmit(data);
            setSnackbarMessage(
              `Machine ${editValues ? "updated" : "created"} successfully`
            );
          } catch (err) {
            handleError(err);
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          values,
          submitCount,
          errors,
          setValues,
          setFieldValue,
        }) => {
          const useDefaultConfigurations = values.useDefaultConfigurations;
          const renderConfigurationFields = () => {
            if (values.productFamily) {
              const productFamily = productFamilies[values.productFamily];
              if (productFamily) {
                return (
                  <>
                    <Typography
                      color="textSecondary"
                      className={classes.marginTop2}
                    >
                      Configurations
                    </Typography>
                    {productFamily.configurations.map((configuration) => {
                      const fieldIsCurrentlyIgnored =
                        values.ignoredDefaultFields[configuration.name];
                      let InputComponent = null;
                      if (configuration.type === "select") {
                        InputComponent = (
                          <FormSelectField
                            key={configuration.name}
                            label={configuration.label}
                            name={configuration.name}
                            options={configuration.options.map((value) => ({
                              value,
                              label: value,
                            }))}
                            disabled={fieldIsCurrentlyIgnored}
                          />
                        );
                      } else if (configuration.type === "number") {
                        InputComponent = (
                          <FormNumberField
                            key={configuration.name}
                            label={configuration.label}
                            name={configuration.name}
                            adornmentText={configuration.unit}
                            disabled={fieldIsCurrentlyIgnored}
                            decimalScale={3}
                            thousandSeparator={true}
                          />
                        );
                      }
                      if (!values.useDefaultConfigurations) {
                        const handleButtonClick = () => {
                          setValues({
                            ...values,
                            ignoredDefaultFields: {
                              ...values.ignoredDefaultFields,
                              [configuration.name]: !fieldIsCurrentlyIgnored,
                            },
                            [configuration.name]: "",
                          });
                        };
                        return (
                          <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            key={configuration.name}
                          >
                            <Grid item xs={11}>
                              {InputComponent}
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip
                                title={
                                  fieldIsCurrentlyIgnored
                                    ? "Enable this field"
                                    : "Disable this field"
                                }
                              >
                                <IconButton
                                  onClick={handleButtonClick}
                                  className={classes.marginTop}
                                >
                                  {fieldIsCurrentlyIgnored ? (
                                    <AddIcon color="primary" />
                                  ) : (
                                    <DeleteIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      }
                      return InputComponent;
                    })}
                  </>
                );
              }
              return null;
            }
            return null;
          };
          const handleSwitchChange = () => {
            setFieldValue("ignoredDefaultFields", {});
          };
          return (
            <div className={classes.grid}>
              <div>
                <Form>
                  <FormSelectField
                    name="productFamily"
                    label="Product Family"
                    options={productFamilyOptions}
                  />
                  <FormSelectField
                    name="manufacturer"
                    label="Manufacturer"
                    options={manufacturerOptions}
                    native
                  />
                  <FormTextField name="name" label="Machine Name" />
                  <FormRichTextField name="description" label="Description" />
                  <FormSwitchField
                    name="useDefaultConfigurations"
                    label="Use default configurations?"
                    handleChange={handleSwitchChange}
                  />
                  {renderConfigurationFields()}
                  {!values.useDefaultConfigurations && (
                    <FieldArray
                      validateOnChange={false}
                      name="additionalConfigurations"
                      render={({ push, remove }) => {
                        const handleAdd = () => {
                          push({
                            label: "",
                            value: "",
                          });
                        };
                        return (
                          <div>
                            <Typography
                              color="textSecondary"
                              className={classes.marginTop2}
                            >
                              Additional Configurations
                            </Typography>
                            {values.additionalConfigurations.map(
                              (configuration: any, index: number) => {
                                const handleRemove = () => {
                                  remove(index);
                                };
                                return (
                                  <Grid
                                    container
                                    key={`config-${index}`}
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <FormTextField
                                        name={`additionalConfigurations.${index}.label`}
                                        label="Label"
                                      />
                                    </Grid>
                                    <Grid item xs={5}>
                                      <FormTextField
                                        name={`additionalConfigurations.${index}.value`}
                                        label="Value"
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip title="Remove this field">
                                        <IconButton
                                          onClick={handleRemove}
                                          className={classes.marginTop}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                            {submitCount > 0 &&
                              typeof errors.configurations === "string" && (
                                <Typography variant="caption" color="error">
                                  {errors.configurations}
                                </Typography>
                              )}
                            <div className={classes.margin}>
                              <Button
                                size="small"
                                onClick={handleAdd}
                                color="primary"
                                variant="outlined"
                                startIcon={<AddIcon />}
                                disabled={useDefaultConfigurations}
                              >
                                ADD CONFIGURATION
                              </Button>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                  <FieldArray
                    validateOnChange={false}
                    name="images"
                    render={({ push, remove }) => {
                      const handleAdd = () => {
                        const initialImageValues: ImageInputValues = {
                          file: null,
                          blob: "",
                          imageURL: "",
                        };
                        push(initialImageValues);
                      };
                      return (
                        <div>
                          <Typography
                            color="textSecondary"
                            className={classes.marginTop2}
                          >
                            Machine Images
                          </Typography>
                          <div>
                            {values.images.map(
                              (image: ImageInputValues, index: number) => {
                                const handleRemoveClick = () => {
                                  remove(index);
                                };
                                return (
                                  <Grid
                                    container
                                    key={`addl-image-${index}`}
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item xs>
                                      <FormImageField
                                        name={`images.${index}`}
                                        label={`Image ${index + 1}`}
                                        hideError
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleRemoveClick}
                                        className={classes.marginTopDelete}
                                      >
                                        DELETE IMAGE {index + 1}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                          </div>
                          {submitCount > 0 &&
                            typeof errors.images === "string" && (
                              <Typography variant="caption" color="error">
                                {errors.images}
                              </Typography>
                            )}
                          <div className={classes.margin}>
                            <Button
                              size="small"
                              onClick={handleAdd}
                              color="primary"
                              variant="outlined"
                              startIcon={<AddIcon />}
                            >
                              ADD IMAGE
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  />
                  {values.productFamily && (
                    <FormTextField
                      name="currentGeneration"
                      label="Current Model Generation"
                    />
                  )}
                  {values.productFamily && (
                    <FieldArray
                      validateOnChange={false}
                      name="generations"
                      render={({ push, remove }) => {
                        const handleAdd = () => {
                          const initValues = productFamilyGenerationFields[
                            values.productFamily
                          ].reduce(
                            (a, b) => {
                              a[b] = "";
                              return a;
                            },
                            { model: "" } as { [key: string]: string }
                          );
                          push(initValues);
                        };
                        const renderGenerationProductFamilies = (
                          index: number
                        ) => {
                          const productFamily =
                            productFamilies[values.productFamily];
                          return productFamily.configurations.map(
                            (configuration) => {
                              if (configuration.type === "number") {
                                return (
                                  <FormNumberField
                                    key={configuration.name}
                                    label={configuration.label}
                                    name={`generations.${index}.${configuration.name}`}
                                    adornmentText={configuration.unit}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                  />
                                );
                              }
                              return null;
                            }
                          );
                        };
                        return (
                          <div>
                            <Typography
                              color="textSecondary"
                              className={classes.marginTop2}
                            >
                              Previous Model Generations
                            </Typography>
                            <div>
                              {values.generations.map(
                                (generation: any, index: number) => {
                                  const handleRemoveClick = () => {
                                    remove(index);
                                  };
                                  return (
                                    <div
                                      key={`generation-${index}`}
                                      className={classes.generationContainer}
                                    >
                                      <Grid
                                        alignItems="center"
                                        justify="space-between"
                                        container
                                      >
                                        <Grid item>
                                          <Typography variant="body2">
                                            <strong>
                                              Generation #{index + 1}
                                            </strong>
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            onClick={handleRemoveClick}
                                            variant="outlined"
                                            startIcon={<DeleteIcon />}
                                            size="small"
                                          >
                                            Remove
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      <FormTextField
                                        label="Model"
                                        name={`generations.${index}.model`}
                                        placeholder={`Only include model name, not maker (e.g. D5A)`}
                                      />
                                      {renderGenerationProductFamilies(index)}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            {submitCount > 0 &&
                              typeof errors.generations === "string" && (
                                <Typography variant="caption" color="error">
                                  {errors.generations}
                                </Typography>
                              )}
                            <div className={classes.margin}>
                              <Button
                                size="small"
                                onClick={handleAdd}
                                color="primary"
                                variant="outlined"
                                startIcon={<AddIcon />}
                              >
                                ADD GENERATION
                              </Button>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                  <FormSubmitButton isSubmitting={isSubmitting} />
                  {handleDelete && (
                    <FormDeleteButton
                      label="Delete Machine"
                      warningMessage="Are you sure you want to delete this machine? This action cannot be undone."
                      successMessage="Machine deleted successfully"
                      handleDelete={handleDelete}
                    />
                  )}
                </Form>
              </div>
              <div>
                <MachineFormPreview
                  name={values.name}
                  description={values.description}
                  imageInputValues={values.images}
                  manufacturer={values.manufacturer}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default MachineForm;
