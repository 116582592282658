import { State } from "../components/Store";

export const UPDATE_ALL = "UPDATE_ALL";
export const UPDATE_ONE = "UPDATE_ONE";
export const ADD_ONE = "ADD_ONE";
export const SET_LOADING = "SET_LOADING";
export const UPDATE_ONE_BY_ID = "UPDATE_ONE_BY_ID";
export const DELETE_ONE_BY_ID = "DELETE_ONE_BY_ID";
export const UPDATE_NEW_REQUEST_COUNT = "UPDATE_NEW_REQUEST_COUNT";

export type UpdateAllPayload = {
  data: Omit<State["data"], "locations" | "blogs">;
  metadata: State["metadata"];
};

interface UpdateNewRequestCountAction {
  type: typeof UPDATE_NEW_REQUEST_COUNT;
  payload: {
    newRequestCount: number;
  };
}

interface UpdateAllAction {
  type: typeof UPDATE_ALL;
  payload: UpdateAllPayload;
}

interface UpdateOneAction {
  type: typeof UPDATE_ONE;
  payload: {
    key: keyof State["data"];
    data: any;
  };
}

interface UpdateOneById {
  type: typeof UPDATE_ONE_BY_ID;
  payload: {
    key: keyof State["data"];
    id: string;
    data: any;
    deleteId?: string;
  };
}

interface SetLoading {
  type: typeof SET_LOADING;
}

interface DeleteOneById {
  type: typeof DELETE_ONE_BY_ID;
  payload: {
    id: string;
    key: keyof State["data"];
  };
}

export type Action =
  | UpdateAllAction
  | UpdateOneAction
  | SetLoading
  | UpdateOneById
  | DeleteOneById
  | UpdateNewRequestCountAction;
