import React from "react";
import firebase from "../firebase";
import { useSnackbar } from "./Snackbar";

interface IContentManagementContext {
  unsavedChanges: boolean;
}
const ContentManagementContext = React.createContext<
  [
    IContentManagementContext,
    React.Dispatch<React.SetStateAction<IContentManagementContext>>
  ]
>([{ unsavedChanges: false }, () => {}]);

export const useContentManagementContext = () => {
  const [state, setState] = React.useContext(ContentManagementContext);
  const [submitting, setSubmitting] = React.useState(false);
  const { setSnackbarMessage } = useSnackbar();
  function toggleUnsavedChanges(unsavedChanges: boolean) {
    setState({ unsavedChanges });
  }
  async function saveChanges() {
    try {
      setSubmitting(true);
      const publishData = firebase.functions().httpsCallable("publishData");
      await publishData();
      setSnackbarMessage(
        "Build request sent! Changes should be published in a few minutes."
      );
      toggleUnsavedChanges(false);
      setSubmitting(false);
    } catch {
      setSubmitting(false);
      window.alert(
        "An error occurred while saving changes - please try again later."
      );
    }
  }
  const hasUnsavedChanges = state.unsavedChanges;
  return { hasUnsavedChanges, toggleUnsavedChanges, saveChanges, submitting };
};

export const ContentManagementContextProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IContentManagementContext>({
    unsavedChanges: false,
  });

  return (
    <ContentManagementContext.Provider value={[state, setState]}>
      {children}
    </ContentManagementContext.Provider>
  );
};
