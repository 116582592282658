import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { MACHINES } from "quipquotes-commons/dist/constants";
import MachinesForm from "./MachineForm";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";
import { fetchMachines } from "../../utils/dataFetchers";
import {
  SET_LOADING,
  UPDATE_ONE,
  UPDATE_ONE_BY_ID,
} from "../../constants/actions";
import useMachineOptions from "../../utils/useMachineOptions";

const PageComponent: React.FC<{
  loading: boolean;
  data: any[];
  handleRefresh: () => void;
}> = ({ loading, data, handleRefresh }) => {
  const { openDialog, updateEditValues } = useFormDialog();
  const { manufacturerOptions, productFamilyOptions } = useMachineOptions();
  function addClick() {
    updateEditValues(null);
    openDialog(true);
  }
  const SearchbarButtons = (
    <div>
      <Button variant="contained" color="primary" onClick={addClick}>
        ADD MACHINE
      </Button>
    </div>
  );
  return (
    <VirtualizedTable
      handleRefresh={handleRefresh}
      filters={[
        {
          label: "Product Family",
          dataKey: "productFamily",
          options: productFamilyOptions,
        },
        {
          label: "Manufacturer",
          dataKey: "manufacturer",
          options: manufacturerOptions,
        },
      ]}
      tableWidth={800}
      searchPlaceholder="Search by machine name"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "productFamily",
          width: 200,
          label: "Product Family",
        },
        {
          dataKey: "manufacturer",
          width: 200,
          label: "Manufacturer",
        },
        {
          dataKey: "name",
          width: 300,
          label: "Name",
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues(rowData);
              openDialog(true);
            };
            return (
              <Tooltip title="Edit">
                <span>
                  <IconButton onClick={handleClick}>
                    <EditIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
      SearchbarButtons={SearchbarButtons}
    />
  );
};

const MachinePage: React.FC = () => {
  const {
    state: {
      data: { machines },
      metadata: { loading },
    },
    dispatch,
  } = useStore();
  const handleRefresh = async () => {
    dispatch({ type: SET_LOADING });
    dispatch({
      type: UPDATE_ONE,
      payload: { key: MACHINES, data: await fetchMachines() },
    });
  };
  const handleSubmit = (data: any) => {
    dispatch({
      type: UPDATE_ONE_BY_ID,
      payload: { key: MACHINES, id: data.id, data },
    });
  };
  return (
    <FormDialogProvider
      formLabel="Machine"
      FormComponent={<MachinesForm handleSubmit={handleSubmit} />}
      fullScreen
    >
      <PageComponent
        data={machines}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default MachinePage;
