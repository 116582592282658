import { useStore } from "../components/Store";

export default function useMachineOptions() {
  const {
    state: {
      data: { manufacturers, productFamilies },
    },
  } = useStore();

  const manufacturerOptions = manufacturers.map((m) => {
    return {
      value: m.id,
      label: m.name,
    };
  });
  const productFamilyOptions = productFamilies.map((fam) => {
    return {
      value: fam.id,
      label: fam.id,
    };
  });

  return { manufacturerOptions, productFamilyOptions };
}
