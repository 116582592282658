import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ImageInputValues } from "../../constants";
import { BlogElement } from "./BlogForm";

const usePreviewStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      ...theme.typography.body1,
    },
    "& h3": {
      ...theme.typography.h3,
      fontWeight: "bold",
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

const useImageStyles = makeStyles((theme) => ({
  imagePreview: {
    width: "100%",
    margin: `${theme.spacing(2)}px 0`,
    background: theme.palette.grey[200],
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    overflow: "hidden",
    "&.aspect-ratio-1": {
      paddingBottom: "100%",
    },
    "&.aspect-ratio-4-5": {
      paddingBottom: "80%",
    },
    "&.aspect-ratio-3-5": {
      paddingBottom: "60%",
    },
    "&.aspect-ratio-1-2": {
      paddingBottom: "50%",
    },
    "& .placeholder": {
      display: "flex",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    "& .image": {
      position: "absolute",
      top: 0,
      left: 0,
      objectFit: "cover",
      objectPosition: "center center",
      width: "100%",
      height: "100%",
    },
  },
  caption: {
    paddingLeft: theme.spacing(2),
    display: "block",
  },
}));

const ImagePreview: React.FC<{
  imageValues: ImageInputValues;
  placeholder: string;
}> = ({ imageValues, placeholder }) => {
  const classes = useImageStyles();
  const src = imageValues.blob || imageValues.imageURL;
  const aspectRatio = imageValues.aspectRatio
    ? imageValues.aspectRatio.replace("/", "-")
    : "4-5";
  return (
    <>
      <div className={`${classes.imagePreview} aspect-ratio-${aspectRatio}`}>
        {src ? (
          <img src={src} className="image" alt="Placeholder" />
        ) : (
          <div className="placeholder">
            <Typography variant="body1" gutterBottom>
              {placeholder}
            </Typography>
          </div>
        )}
      </div>
      {imageValues.caption && (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.caption}
        >
          {imageValues.caption}
        </Typography>
      )}
    </>
  );
};

interface BlogFormPreviewProps {
  title: string;
  description: string;
  headerImage: ImageInputValues;
  elements: BlogElement[];
}
const BlogFormPreview: React.FC<BlogFormPreviewProps> = ({
  title,
  description,
  headerImage,
  elements,
}) => {
  const classes = usePreviewStyles();
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        <strong>{title || "Blog Title"}</strong>
      </Typography>
      <Typography>{description || "Blog Description"}</Typography>
      <div>
        <ImagePreview imageValues={headerImage} placeholder="Header Image" />
      </div>
      {elements.map((element, index) => {
        if (element.type === "text") {
          return (
            <div
              key={`element-${index}`}
              dangerouslySetInnerHTML={{ __html: element.value }}
              className={classes.root}
            />
          );
        } else if (element.type === "image") {
          return (
            <ImagePreview
              imageValues={element.value}
              key={`element-${index}`}
              placeholder={`Element #${index + 1} Image`}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default BlogFormPreview;
