import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import FireTypes from "firebase";
import Navbar from "./Navbar";
import LoadingIcon from "./LoadingIcon";
import LoginPage from "./LoginPage";
import { SnackbarProvider, SnackbarElement } from "./Snackbar";
import firebase from "../firebase";
import { ContentManagementContextProvider } from "./ContentManagementContext";
import HomePage from "./HomePage";
import ManufacturerPage from "./Manufacturer/ManufacturerPage";
import MachinePage from "./Machine/MachinePage";
import DealerPage from "./Dealer/DealerPage";
import RequestPage from "./Request/RequestPage";
import LocationPage from "./Location/LocationPage";
import { StoreProvider, useStore } from "./Store";
import { fetchAllData } from "../utils/dataFetchers";
import { SET_LOADING, UPDATE_ALL } from "../constants/actions";
import ProductFamilyPage from "./ProductFamily/ProductFamilyPage";
import BlogPage from "./Blog/BlogPage";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const Main: React.FC<{ authUser: FireTypes.User | null }> = ({ authUser }) => {
  const { dispatch } = useStore();

  const refreshData = React.useCallback(async () => {
    dispatch({ type: SET_LOADING });
    const payload = await fetchAllData();
    dispatch({ type: UPDATE_ALL, payload });
  }, [dispatch]);

  React.useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/requests">
          <RequestPage authUser={authUser} />
        </Route>
        <Route path="/manufacturers">
          <ManufacturerPage />
        </Route>
        <Route path="/machines">
          <MachinePage />
        </Route>
        <Route path="/product-families">
          <ProductFamilyPage />
        </Route>
        <Route path="/dealers">
          <DealerPage authUser={authUser} />
        </Route>
        <Route path="/locations">
          <LocationPage />
        </Route>
        <Route path="/blogs">
          <BlogPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </>
  );
};

const Root: React.FC = () => {
  const [authenticating, setAuthenticating] = React.useState(true);
  const [authUser, setAuthUser] = React.useState<FireTypes.User | null>(null);
  const classes = useStyles();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
      setAuthenticating(false);
    });
  }, []);

  function renderContent() {
    if (authenticating) {
      return (
        <div className={classes.root}>
          <LoadingIcon text="Loading..." />
        </div>
      );
    } else if (!authUser) {
      return <LoginPage />;
    }
    return (
      <SnackbarProvider>
        <BrowserRouter>
          <ContentManagementContextProvider>
            <StoreProvider>
              <Main authUser={authUser} />
            </StoreProvider>
          </ContentManagementContextProvider>
        </BrowserRouter>
        <SnackbarElement />
      </SnackbarProvider>
    );
  }
  return renderContent();
};

export default Root;
