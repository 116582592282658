import React from "react";
import FireTypes from "firebase";
import {
  DealerLocation,
  ParentRequest,
  Request,
  DealerAccount,
  BASE_QUIPQUOTES_DEALERS_URL,
} from "quipquotes-commons";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { handleError } from "../../utils";
import firebase from "../../firebase";
import LoadingIcon from "../LoadingIcon";
import DealerContactedSwitch from "./DealerContactedSwitch";
import EmailPreviewModal from "./EmailPreviewModal";
import CustomerDialog from "./CustomerDialog";
import CreateDealerAccountModal from "./CreateDealerAccountModal";
import ViewQuotesModal from "./ViewQuotesModal";
import DealerAccountButton from "./DealerAccountButton";
import { useSnackbar } from "../Snackbar";
import UpdateRequestStatusModal from "./UpdateRequestStatusModal";

export type AdminRequest = Request & {
  id: string;
  location: DealerLocation;
  dealerAccounts: Array<DealerAccount & { id: string }>;
};

const useRootStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },
}));

const useRequestItemStyles = makeStyles((theme) => ({
  requestContainer: {
    padding: `${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
  },
  linkButton: {
    fontWeight: "bold",
    textAlign: "left",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    background: "none",
    padding: 0,
    outline: "none",
    border: "none",
    cursor: "pointer",
    "&:hover, &:focus": {
      color: theme.palette.primary.dark,
    },
    display: "block",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  actions: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "& .switch": {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  chip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
}));

const RequestItem: React.FC<{
  request: AdminRequest;
  parentRequest: ParentRequest;
  authUser: FireTypes.User | null;
}> = ({ request, parentRequest, authUser }) => {
  const [activeDealerAccountId, setActiveDealerAccountId] = React.useState("");
  const classes = useRequestItemStyles();
  const dealerLink = `${BASE_QUIPQUOTES_DEALERS_URL}/requests/${request.parentRequestId}/${request.id}`;
  const { setSnackbarMessage } = useSnackbar();

  const copyId = async () => {
    await window.navigator.clipboard.writeText(request.dealerId);
    setSnackbarMessage("Dealer ID copied to clipboard");
  };

  return (
    <div key={request.id} className={classes.requestContainer}>
      <Grid container spacing={1}>
        <DataItem label="Status" value={request.status} />
        <DataItem label="Machine" value={request.machineName} />
        <Grid item xs={4}>
          <Typography variant="caption" color="textSecondary">
            Dealer
          </Typography>
          <Tooltip title="Click to copy dealer ID">
            <button className={classes.linkButton} onClick={copyId}>
              {`${request.location.dealerName} - ${request.location.locationName}`}
            </button>
          </Tooltip>
        </Grid>
        <DataItem
          label="City"
          value={`${request.location.city}, ${request.location.state}`}
        />
        <DataItem
          label="Customer"
          value={
            <CustomerDialog accountDetails={parentRequest.accountDetails} />
          }
        />
        <Grid item xs={4}>
          <Typography variant="caption" color="textSecondary">
            Link to Request
          </Typography>
          <a
            className={`${classes.linkButton} ${classes.ellipsis}`}
            href={dealerLink}
            target="_blank"
            rel="noreferrer"
          >
            {dealerLink}
          </a>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Dealer Accounts (click to select for emailing)
          </Typography>
          <div>
            {request.dealerAccounts.length > 0 ? (
              request.dealerAccounts.map((dealerAccount) => {
                const active = activeDealerAccountId === dealerAccount.id;
                const handleClick = () => {
                  setActiveDealerAccountId(active ? "" : dealerAccount.id);
                };
                return (
                  <DealerAccountButton
                    key={dealerAccount.id}
                    dealerAccount={dealerAccount}
                    onClick={handleClick}
                    active={active}
                  />
                );
              })
            ) : (
              <Typography variant="body2">
                <strong>
                  <em>No accounts yet</em>
                </strong>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.actions} spacing={2}>
        <Grid item>
          <ViewQuotesModal quotes={request.quotes} />
        </Grid>
        <Grid item>
          <EmailPreviewModal
            parentRequestId={request.parentRequestId}
            requestId={request.id}
            dealerAccountId={activeDealerAccountId}
            authUser={authUser}
            dealerHasBeenContacted={request.dealerHasBeenContacted}
          />
        </Grid>
        <Grid item>
          <CreateDealerAccountModal
            dealerId={request.location.dealerId}
            dealerName={request.location.dealerName}
            authUser={authUser}
            formLocation="requests"
          />
        </Grid>
        <Grid item>
          <UpdateRequestStatusModal
            requestStatus={request.status}
            requestId={request.id}
          />
        </Grid>
        <Grid item>
          <DealerContactedSwitch
            requestId={request.id}
            dealerHasBeenContacted={request.dealerHasBeenContacted}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const RequestForm: React.FC<{ authUser: FireTypes.User | null }> = ({
  authUser,
}) => {
  const classes = useRootStyles();
  const [fetchingData, setFetchingData] = React.useState(true);
  const { editValues, updateEditValues } = useFormDialog();
  const fetchParentRequest = React.useCallback(async () => {
    try {
      const getParentRequest = firebase
        .functions()
        .httpsCallable("getParentRequest");
      const { data } = await getParentRequest({ id: editValues.id });
      updateEditValues({
        id: editValues.id,
        parentRequest: data.parentRequest,
        requests: data.requests,
      });
      setFetchingData(false);
    } catch (err) {
      handleError(err as Error);
    }
  }, [editValues, updateEditValues]);
  React.useEffect(() => {
    if (editValues && editValues.fetchRequestData) {
      fetchParentRequest();
    } else {
      setFetchingData(false);
    }
  }, [editValues, fetchParentRequest]);
  if (fetchingData) {
    return <LoadingIcon text="Loading request data..." />;
  }
  const requestData = editValues as {
    parentRequest: ParentRequest;
    requests: AdminRequest[];
  };
  return (
    <div className={classes.root}>
      {requestData.requests.map((request) => (
        <RequestItem
          key={request.id}
          request={request}
          authUser={authUser}
          parentRequest={editValues.parentRequest}
        />
      ))}
    </div>
  );
};

const DataItem: React.FC<{ label: string; value: React.ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <Grid item xs={4}>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography variant="body2">
        {value === "" ? (
          <strong>
            <em>None provided</em>
          </strong>
        ) : (
          value
        )}
      </Typography>
    </Grid>
  );
};

export default RequestForm;
