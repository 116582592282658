import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { FIREBASE_CREDENTIALS } from "./config";

const getInstance = () => {
  let instance = firebase.initializeApp(FIREBASE_CREDENTIALS);
  if (window.location.hostname === "localhost") {
    instance.functions().useEmulator("localhost", 5001);
  }
  return instance;
};
const instance = getInstance();
export default instance;
