import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ImageInputValues } from "../../constants";

const logoImageWidth = 180;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    background: theme.palette.grey[200],
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    "&.logo": {
      boxShadow: theme.shadows[3],
      marginLeft: theme.spacing(2),
      marginTop: -logoImageWidth / 2,
      borderRadius: theme.shape.borderRadius,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover",
    objectPosition: "center center",
    width: "100%",
    height: "100%",
  },
  infoContainer: {
    padding: theme.spacing(2),
    "& p": {
      fontSize: "1rem",
      fontFamily: "inherit",
      lineHeight: 1.5,
      marginTop: 0,
    },
  },
}));

const ImagePreview: React.FC<{
  src: string;
  recommendedSize: string;
  width: number | string;
  height: number | string;
  name: string;
  className: string;
}> = ({ src, recommendedSize, width, height, name, className }) => {
  const classes = useStyles();
  return (
    <div className={className} style={{ width, height }}>
      {src ? (
        <img src={src} className={classes.image} alt="Placeholder" />
      ) : (
        <div>
          <Typography variant="body1" gutterBottom>
            {name.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Recommended Size: {recommendedSize}
          </Typography>
        </div>
      )}
    </div>
  );
};

interface FormPreviewProps {
  logoImageInputValues: ImageInputValues;
  bannerImageInputValues: ImageInputValues;
  name: string;
  description: string;
}

const ManufacturerFormPreview: React.FC<FormPreviewProps> = ({
  logoImageInputValues,
  bannerImageInputValues,
  name,
  description,
}) => {
  const classes = useStyles();
  const logoSrc = logoImageInputValues.blob || logoImageInputValues.imageURL;
  const bannerSrc =
    bannerImageInputValues.blob || bannerImageInputValues.imageURL;
  return (
    <div>
      <ImagePreview
        src={bannerSrc}
        width="100%"
        height={200}
        recommendedSize="1600x600"
        name="Banner Image"
        className={classes.imageContainer}
      />
      <ImagePreview
        width={logoImageWidth}
        height={logoImageWidth}
        src={logoSrc}
        recommendedSize="500x500"
        name="Logo Image"
        className={`${classes.imageContainer} logo`}
      />
      <div className={classes.infoContainer}>
        <Typography variant="h4" gutterBottom>
          <strong>{name}</strong>
        </Typography>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
};

export default ManufacturerFormPreview;
