import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Grid from "@material-ui/core/Grid";
import FormSelectField from "../FormHelpers/FormSelectField";
import FormTextField from "../FormHelpers/FormTextField";
import FormSubmitButton from "../FormHelpers/FormSubmitButton";
import FormDeleteButton from "../FormHelpers/FormDeleteButton";
import { useFormDialog } from "../FormHelpers/FormDialog";
import {
  generateInitialValues,
  generateSubmitValues,
  handleError,
} from "../../utils";
import { InputField } from "../../constants";
import firebase from "../../firebase";
import { useSnackbar } from "../Snackbar";
import { useContentManagementContext } from "../ContentManagementContext";
import { useStore } from "../Store";
import useMachineOptions from "../../utils/useMachineOptions";
import states from "../../constants/states";
import { DELETE_ONE_BY_ID } from "../../constants/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const fields: InputField[] = [
  { name: "dealerId" },
  { name: "locationName" },
  { name: "address" },
  { name: "city" },
  { name: "state" },
  { name: "zip" },
  { name: "manufacturers", initialValue: [] },
];

const LocationForm: React.FC<{
  handleSubmit: (location: any, deleteId?: string) => void;
}> = ({ handleSubmit }) => {
  const classes = useStyles();
  const { editValues, openDialog } = useFormDialog();
  const { manufacturerOptions } = useMachineOptions();
  const initialValues = generateInitialValues(fields, editValues);
  const { setSnackbarMessage } = useSnackbar();
  const { toggleUnsavedChanges } = useContentManagementContext();
  const {
    state: {
      data: { dealers },
    },
    dispatch,
  } = useStore();
  const handleDelete = editValues
    ? async () => {
        const deleteLocation = firebase
          .functions()
          .httpsCallable("deleteLocation");
        await deleteLocation({ id: editValues.id });
        dispatch({
          type: DELETE_ONE_BY_ID,
          payload: { id: editValues.id, key: "locations" },
        });
        toggleUnsavedChanges(true);
        openDialog(false);
      }
    : undefined;
  const dealerOptions = dealers.map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          [
            { name: "dealerId", label: "dealer" },
            { name: "locationName", label: "location name" },
            { name: "address", label: "address" },
            { name: "city", label: "city" },
            { name: "state", label: "state" },
            { name: "zip", label: "zip" },
          ].forEach(({ name, label }) => {
            if (!values[name]) {
              errors[name] = `Please provide a ${label}`;
            }
          });
          if (values.manufacturers.length === 0) {
            errors.manufacturers = "Please select at least one manufacturer";
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            const actionName = editValues ? "updateLocation" : "createLocation";
            const action = firebase.functions().httpsCallable(actionName);
            const submitValues = await generateSubmitValues(
              values,
              fields,
              editValues,
              "location"
            );
            const { data } = await action(submitValues);
            actions.setSubmitting(false);
            openDialog(false);
            toggleUnsavedChanges(true);
            handleSubmit(
              data,
              editValues && data.id !== editValues.id
                ? editValues.id
                : undefined
            );
            setSnackbarMessage(
              `Location ${editValues ? "updated" : "created"} successfully`
            );
          } catch (err) {
            handleError(err);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <div className={classes.root}>
              <div>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormSelectField
                        name="dealerId"
                        label="Dealer"
                        margin="none"
                        options={dealerOptions}
                        native
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField
                        name="locationName"
                        label="Location Name"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField
                        name="address"
                        label="Address"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField name="city" label="City" margin="none" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectField
                        name="state"
                        label="State"
                        margin="none"
                        options={states}
                        native
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField
                        name="zip"
                        label="ZIP Code"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectField
                        name="manufacturers"
                        label="Manufacturers"
                        margin="none"
                        options={manufacturerOptions}
                        multiple
                      />
                    </Grid>
                  </Grid>
                  <FormSubmitButton isSubmitting={isSubmitting} />
                  {handleDelete && (
                    <FormDeleteButton
                      label="Delete Location"
                      warningMessage="Are you sure you want to delete this location? This action cannot be undone."
                      successMessage="Location deleted successfully"
                      handleDelete={handleDelete}
                    />
                  )}
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default LocationForm;
