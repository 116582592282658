import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { DEALER_LOCATIONS } from "quipquotes-commons";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import LocationForm from "./LocationForm";
import firebase from "../../firebase";
import { useStore } from "../Store";
import { UPDATE_ONE, UPDATE_ONE_BY_ID } from "../../constants/actions";
import states from "../../constants/states";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

const PageComponent: React.FC<{
  loading: boolean;
  data: any[];
  handleRefresh: () => void;
}> = ({ loading, data, handleRefresh }) => {
  const classes = useStyles();
  const {
    state: {
      data: { dealers },
    },
  } = useStore();
  const dealerOptions = dealers.map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });
  const { openDialog, updateEditValues } = useFormDialog();
  function addClick() {
    updateEditValues(null);
    openDialog(true);
  }
  const SearchbarButtons = (
    <div>
      <Button variant="contained" color="primary" onClick={addClick}>
        Add Location
      </Button>
    </div>
  );
  return (
    <VirtualizedTable
      filters={[
        {
          label: "Dealer",
          dataKey: "dealerId",
          options: dealerOptions,
        },
        {
          label: "State",
          dataKey: "state",
          options: states,
        },
      ]}
      handleRefresh={handleRefresh}
      tableWidth={1200}
      searchPlaceholder="Search by dealer, manufacturer or location"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "dealerName",
          width: 300,
          label: "Dealer Name",
          cellRenderer: ({ rowData }) => (
            <Link
              to={`/dealers?id=${rowData.dealerId}`}
              className={classes.link}
            >
              {rowData.dealerName}
            </Link>
          ),
        },
        {
          dataKey: "locationName",
          width: 200,
          label: "Location Name",
        },
        {
          dataKey: "manufacturerString",
          width: 300,
          label: "Manufacturers",
        },
        {
          dataKey: "state",
          width: 100,
          label: "State",
        },
        {
          dataKey: "city",
          width: 150,
          label: "City",
        },
        {
          dataKey: "zip",
          width: 100,
          label: "ZIP",
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues(rowData);
              openDialog(true);
            };
            return (
              <Tooltip title="Edit">
                <span>
                  <IconButton onClick={handleClick}>
                    <EditIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
      SearchbarButtons={SearchbarButtons}
    />
  );
};

const DealerPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const {
    state: {
      data: { locations },
    },
    dispatch,
  } = useStore();
  const handleRefresh = React.useCallback(async () => {
    setLoading(true);
    const data: any[] = [];
    const { docs } = await firebase
      .firestore()
      .collection(DEALER_LOCATIONS)
      .get();
    docs.forEach((doc: any) => {
      const docData = doc.data();
      data.push({
        id: doc.id,
        ...docData,
        manufacturerString: docData.manufacturers.join(", "),
      });
    });
    dispatch({
      type: UPDATE_ONE,
      payload: { key: "locations", data },
    });
    setLoading(false);
  }, [dispatch]);
  const handleSubmit = (data: any, deleteId?: string) => {
    dispatch({
      type: UPDATE_ONE_BY_ID,
      payload: {
        key: "locations",
        id: data.id,
        data: { ...data, manufacturerString: data.manufacturers.join(", ") },
        deleteId,
      },
    });
  };
  React.useEffect(() => {
    if (locations.length === 0) {
      handleRefresh();
    }
    // eslint-disable-next-line
  }, [handleRefresh]);

  return (
    <FormDialogProvider
      formLabel="Location"
      FormComponent={<LocationForm handleSubmit={handleSubmit} />}
      maxWidth="md"
    >
      <PageComponent
        data={locations}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default DealerPage;
