import React from "react";
import { Field, FieldProps } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface FormSwitchFieldProps {
  name: string;
  label: string;
  handleChange?: () => void;
}

const FormSwitchField: React.FC<FormSwitchFieldProps> = ({
  name,
  label,
  handleChange: handleChangeProp,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const checked = field.value === true;
        const handleChange = () => {
          form.setFieldValue(name, !field.value);
          if (handleChangeProp) {
            handleChangeProp();
          }
        };
        return (
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleChange}
                name={name}
                color="primary"
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

export default FormSwitchField;
