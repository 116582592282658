import { productFamilies } from "quipquotes-commons";
import {
  DEALERS,
  MACHINES,
  MANUFACTURERS,
  PARENT_REQUESTS,
} from "quipquotes-commons/dist/constants";
import { getNewRequestCount } from ".";
import { UpdateAllPayload } from "../constants/actions";
import firebase from "../firebase";

const fetcherFactory = (collectionName: string) => async (): Promise<any[]> => {
  const data: any[] = [];
  const { docs } = await firebase
    .firestore()
    .collection(collectionName)
    .orderBy("name")
    .get();
  docs.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

export const fetchParentRequests = async () => {
  const data: any[] = [];
  const { docs } = await firebase
    .firestore()
    .collection(PARENT_REQUESTS)
    .orderBy("createdAt", "desc")
    .get();
  docs.forEach((doc) => {
    const requestData = doc.data();
    const request = {
      allRequestsCompleted:
        requestData.totalRequests === requestData.completedRequests
          ? "Yes"
          : "No",
      customerName: requestData.accountDetails.name,
      customerEmail: requestData.accountDetails.email,
      createdAt: requestData.createdAt,
      deliveryLocation: requestData.deliveryLocation,
      completedRequests: requestData.completedRequests,
      totalRequests: requestData.totalRequests,
      productFamily: requestData.productFamily,
    };
    data.push({ id: doc.id, ...request });
  });
  return data;
};

export const fetchManufacturers = fetcherFactory(MANUFACTURERS);
export const fetchMachines = fetcherFactory(MACHINES);
export const fetchDealers = fetcherFactory(DEALERS);

export const fetchAllData = async (): Promise<UpdateAllPayload> => {
  const [manufacturers, machines, dealers, parentRequests] = await Promise.all([
    fetchManufacturers(),
    fetchMachines(),
    fetchDealers(),
    fetchParentRequests(),
  ]);

  return {
    data: {
      manufacturers,
      machines,
      dealers,
      parentRequests,
      productFamilies: Object.keys(productFamilies).map((id) => {
        return {
          id,
          ...productFamilies[id],
        };
      }),
    },
    metadata: {
      loading: false,
      newRequestCount: getNewRequestCount(parentRequests),
    },
  };
};
