import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../firebase";
import { useSnackbar } from "./Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}));

const SaveBackupButton: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const classes = useStyles();
  const { setSnackbarMessage } = useSnackbar();

  async function handleClick() {
    const res = window.confirm("Backup database?");
    if (res) {
      try {
        setSubmitting(true);
        const saveDatabaseBackup = firebase
          .functions()
          .httpsCallable("saveDatabaseBackup");
        await saveDatabaseBackup();
        setSnackbarMessage("Database backed up successfully");
        setSubmitting(false);
      } catch {
        setSubmitting(false);
        window.alert(
          "An error occurred while saving data - please try again later."
        );
      }
    }
  }

  return (
    <div className={classes.root}>
      <Button disabled={submitting} onClick={handleClick}>
        Backup Database
      </Button>
    </div>
  );
};

export default SaveBackupButton;
