import FireTypes from "firebase";
import firebase from "../firebase";
import { InputField, ImageInputValues } from "../constants";

export const handleError = (err: any) => {
  console.log(err);
  window.alert(`Error - ${err.message}`);
};

export const replaceParagraphRegEx = /<(\/)?p>/g;

export const generateInitialValues = (
  fields: InputField[],
  editValues: any
) => {
  const initialValues: { [key: string]: any } = {};
  if (editValues) {
    fields.forEach((field: InputField) => {
      if (field.imageField) {
        const imageValues: ImageInputValues = {
          file: null,
          blob: "",
          imageURL: editValues[field.name].imageURL,
          fileName: editValues[field.name].fileName,
        };
        if (field.imageField === "aspectRatioAndCaption") {
          imageValues.aspectRatio = editValues[field.name].aspectRatio;
          imageValues.caption = editValues[field.name].caption;
        }
        initialValues[field.name] = imageValues;
      } else if (field.formatFunction) {
        initialValues[field.name] = field.formatFunction(
          editValues[field.name]
        );
      } else {
        initialValues[field.name] = editValues[field.name];
      }
    });
  } else {
    fields.forEach((field: InputField) => {
      if (field.imageField) {
        const imageValues: ImageInputValues = {
          file: null,
          blob: "",
          imageURL: "",
        };
        initialValues[field.name] = imageValues;
      } else if (field.imageArray) {
        initialValues[field.name] = [];
      } else {
        initialValues[field.name] =
          field.initialValue === false ? false : field.initialValue || "";
      }
    });
  }
  return initialValues;
};
export function formatPhone(number: string) {
  return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
}

interface ImageData {
  imageURL: string;
  fileName: string;
  aspectRatio?: string;
  caption?: string;
}

export const processImage = async (
  imageInputValues: ImageInputValues,
  imagePrefix: string
): Promise<ImageData> => {
  const imageData: ImageData = {
    imageURL: imageInputValues.imageURL,
    fileName: imageInputValues.fileName || "",
  };
  if (imageInputValues.file) {
    const storageRef = firebase.storage().ref();
    const fileName = `${imagePrefix}-${Date.now()}`;
    const image = await storageRef.child(fileName).put(imageInputValues.file);
    const imageURL = await image.ref.getDownloadURL();
    imageData.fileName = fileName;
    imageData.imageURL = imageURL;
  }
  if (imageInputValues.aspectRatio) {
    imageData.aspectRatio = imageInputValues.aspectRatio;
  }
  if (imageInputValues.caption) {
    imageData.caption = imageInputValues.caption;
  }
  return imageData;
};

const processImageOnSubmit = async (
  imageField: ImageInputValues,
  imagePrefix: string
): Promise<ImageData> => {
  if (imageField.file) {
    const imageData = await processImage(imageField, imagePrefix);
    return imageData;
  }
  if (!imageField.imageURL || !imageField.fileName) {
    throw new Error("No image file was found");
  }
  const imageData: ImageData = {
    imageURL: imageField.imageURL,
    fileName: imageField.fileName,
  };
  if (imageField.aspectRatio) {
    imageData.aspectRatio = imageField.aspectRatio;
  }
  if (imageField.caption) {
    imageData.caption = imageField.caption;
  }
  return imageData;
};

export const generateSubmitValues = async (
  values: any,
  fields: InputField[],
  editValues: any,
  formPrefix: string
) => {
  const data: { [key: string]: any } = {};
  for (const field of fields) {
    if (field.imageField) {
      data[field.name] = await processImageOnSubmit(
        values[field.name],
        formPrefix
      );
    } else if (field.imageArray) {
      data[field.name] = [];
      for (let i = 0; i < values[field.name].length; i++) {
        data[field.name][i] = await processImageOnSubmit(
          values[field.name][i],
          formPrefix
        );
      }
    } else {
      data[field.name] = values[field.name];
    }
  }
  if (editValues) {
    return {
      id: editValues.id,
      data,
    };
  }
  return data;
};

export const getNewRequestCount = (parentRequests: any[]) =>
  parentRequests.filter(
    ({ allRequestsCompleted }) => allRequestsCompleted === "No"
  ).length;

export const setAuthHeaders = async (user: FireTypes.User | null) => {
  if (user) {
    return { Authorization: `Bearer ${await user.getIdToken()}` };
  }
  return {};
};
