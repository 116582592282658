import React from "react";
import Grid from "@material-ui/core/Grid";
import { accountFields, SanitizedAccountDetails } from "quipquotes-commons";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import RequestDialog from "./RequestDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      color: theme.palette.primary.dark,
    },
    outline: "none",
    border: "none",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "truncate",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const CustomerDialog: React.FC<{
  accountDetails: SanitizedAccountDetails & Record<string, string>;
}> = ({ accountDetails }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      <span
        tabIndex={0}
        role="button"
        onClick={handleOpen}
        className={classes.button}
      >
        {accountDetails.name}
      </span>
      <RequestDialog
        open={open}
        handleClose={handleClose}
        title="Customer Details"
      >
        <Grid container spacing={2}>
          {accountFields.map((field) => {
            return (
              <DataItem
                key={field.name}
                value={accountDetails[field.name]}
                label={field.label}
              />
            );
          })}
        </Grid>
      </RequestDialog>
    </>
  );
};

const DataItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Grid item xs={6}>
      <Typography variant="caption">{label}</Typography>
      <Typography>
        <strong>{value}</strong>
      </Typography>
    </Grid>
  );
};

export default CustomerDialog;
