import React from "react";
import FireTypes from "firebase";
import * as superagent from "superagent";
import { Formik, Form } from "formik";
import { DealerAccount, DEALER_ACCOUNTS } from "quipquotes-commons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import RequestDialog from "../Request/RequestDialog";
import CreateDealerAccountModal from "../Request/CreateDealerAccountModal";
import FormTextField from "../FormHelpers/FormTextField";
import FormPhoneField from "../FormHelpers/FormPhoneField";
import firebase from "../../firebase";
import { formatPhone, handleError, setAuthHeaders } from "../../utils";
import { useSnackbar } from "../Snackbar";
import { useFormDialog } from "../FormHelpers/FormDialog";
import { API_ENDPOINT } from "../../config";

const useStyles = makeStyles((theme) => ({
  dataItem: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& .buttons": {
      marginTop: theme.spacing(1),
    },
  },
}));

const DealerAccountsDialog: React.FC<{
  dealerAccounts: (DealerAccount & { id: string })[];
  dealerId: string;
  dealerName: string;
  authUser: FireTypes.User | null;
}> = ({ dealerAccounts, dealerId, dealerName, authUser }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Dealer Accounts ({dealerAccounts.length})
      </Button>
      <RequestDialog
        open={open}
        handleClose={handleClose}
        title="Dealer Accounts"
      >
        <div>
          {dealerAccounts.map((account) => {
            return (
              <div key={account.email} className={classes.dataItem}>
                <DealerAccountContainer account={account} authUser={authUser} />
              </div>
            );
          })}
        </div>
        <div>
          <CreateDealerAccountModal
            dealerId={dealerId}
            dealerName={dealerName}
            authUser={authUser}
            formLocation="dealers"
          />
        </div>
      </RequestDialog>
    </>
  );
};

const DealerAccountContainer: React.FC<{
  account: DealerAccount & { id: string };
  authUser: FireTypes.User | null;
}> = ({ account, authUser }) => {
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const toggleEditing = () => {
    setEditing((current) => !current);
  };
  const { setSnackbarMessage } = useSnackbar();
  const { editValues, updateEditValues } = useFormDialog();
  if (editing) {
    return (
      <>
        <Formik
          initialValues={{
            name: account.name,
            phone: account.phone.replace(/[()\- ]/g, ""),
          }}
          validate={(values) => {
            if (values.phone && values.phone.length !== 10) {
              return { phone: "Please enter a valid phone number" };
            }
            return {};
          }}
          onSubmit={async (values, actions) => {
            try {
              const updatedPhone = values.phone
                ? formatPhone(values.phone)
                : "";
              firebase
                .firestore()
                .collection(DEALER_ACCOUNTS)
                .doc(account.id)
                .update({
                  name: values.name,
                  phone: updatedPhone,
                });
              updateEditValues({
                ...editValues,
                dealerAccounts: editValues.dealerAccounts.map((da: any) => {
                  if (account.email === da.email) {
                    return {
                      ...da,
                      name: values.name,
                      phone: updatedPhone,
                    };
                  }
                  return da;
                }),
              });
              setSnackbarMessage("Dealer account updated");
              setEditing(false);
            } catch (err) {
              handleError(err);
              actions.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DataItem label="Email" value={account.email} />
              <FormTextField name="name" label="Name" />
              <FormPhoneField name="phone" />
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={toggleEditing}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    );
  }
  const handleDelete = async () => {
    const res = window.confirm("Delete this dealer account?");
    if (res) {
      setDeleting(true);
      try {
        await superagent
          .delete(`${API_ENDPOINT}/admin/delete-dealer-account`)
          .send({
            dealerAccountId: account.id,
          })
          .set(await setAuthHeaders(authUser));
        updateEditValues({
          ...editValues,
          dealerAccounts: editValues.dealerAccounts.filter(
            (da: any) => account.email !== da.email
          ),
        });
        setSnackbarMessage("Dealer account deleted successfully");
      } catch (err) {
        handleError(err);
        setDeleting(false);
      }
    }
  };
  return (
    <div>
      <DataItem label="Email" value={account.email} />
      <DataItem label="Name" value={account.name || "None provided"} />
      <DataItem label="Phone" value={account.phone || "None provided"} />
      <Grid container spacing={2} className="buttons">
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={toggleEditing}
          >
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button size="small" onClick={handleDelete} disabled={deleting}>
            Delete
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const DataItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <Typography>
        <strong>{value}</strong>
      </Typography>
    </div>
  );
};

export default DealerAccountsDialog;
