import React from "react";
import FireTypes from "firebase";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { DEALERS } from "quipquotes-commons/dist/constants";
import DealerForm from "./DealerForm";
import VirtualizedTable from "../VirtualizedTable";
import { FormDialogProvider, useFormDialog } from "../FormHelpers/FormDialog";
import { useStore } from "../Store";
import { fetchDealers } from "../../utils/dataFetchers";
import {
  SET_LOADING,
  UPDATE_ONE,
  UPDATE_ONE_BY_ID,
} from "../../constants/actions";
import { useLocation } from "react-router-dom";

const PageComponent: React.FC<{
  loading: boolean;
  data: any[];
  handleRefresh: () => void;
}> = ({ loading, data, handleRefresh }) => {
  const location = useLocation();
  const { openDialog, updateEditValues } = useFormDialog();
  function addClick() {
    updateEditValues(null);
    openDialog(true);
  }
  const SearchbarButtons = (
    <div>
      <Button variant="contained" color="primary" onClick={addClick}>
        ADD DEALER
      </Button>
    </div>
  );
  React.useEffect(() => {
    if (location.search) {
      const id = location.search.slice(4);
      updateEditValues({ id, fetchDealerData: true });
      openDialog(true);
    }
    // eslint-disable-next-line
  }, [location]);
  return (
    <VirtualizedTable
      handleRefresh={handleRefresh}
      tableWidth={800}
      searchPlaceholder="Search by dealer name"
      loading={loading}
      data={data}
      columns={[
        {
          dataKey: "name",
          width: 500,
          label: "Name",
        },
        {
          dataKey: "locationCount",
          width: 150,
          label: "Location Count",
          isNotString: true,
        },
        {
          dataKey: "accountCount",
          width: 150,
          label: "Dealer Accounts",
          isNotString: true,
        },
        {
          dataKey: "id",
          width: 50,
          cellRenderer: ({ rowData }) => {
            const handleClick = () => {
              updateEditValues({ id: rowData.id, fetchDealerData: true });
              openDialog(true);
            };
            return (
              <Tooltip title="Edit">
                <span>
                  <IconButton onClick={handleClick}>
                    <EditIcon fontSize="small" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        },
      ]}
      SearchbarButtons={SearchbarButtons}
    />
  );
};

const DealerPage: React.FC<{ authUser: FireTypes.User | null }> = ({
  authUser,
}) => {
  const {
    state: {
      data: { dealers },
      metadata: { loading },
    },
    dispatch,
  } = useStore();
  const handleRefresh = async () => {
    dispatch({ type: SET_LOADING });
    dispatch({
      type: UPDATE_ONE,
      payload: { key: DEALERS, data: await fetchDealers() },
    });
  };
  const handleSubmit = (data: any) => {
    dispatch({
      type: UPDATE_ONE_BY_ID,
      payload: { key: DEALERS, id: data.id, data },
    });
  };
  return (
    <FormDialogProvider
      formLabel="Dealer"
      FormComponent={
        <DealerForm handleSubmit={handleSubmit} authUser={authUser} />
      }
      fullScreen
    >
      <PageComponent
        data={dealers}
        loading={loading}
        handleRefresh={handleRefresh}
      />
    </FormDialogProvider>
  );
};

export default DealerPage;
