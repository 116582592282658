import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ArrowIcon from "@material-ui/icons/ExpandLess";
import Typography from "@material-ui/core/Typography";
import states from "../../constants/states";
import FormTextField from "../FormHelpers/FormTextField";
import FormSelectField from "../FormHelpers/FormSelectField";
import useMachineOptions from "../../utils/useMachineOptions";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridColumnGap: theme.spacing(2),
  },
  truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  infoContainer: {
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(1)}px 0`,
    padding: "2px 8px",
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  removeButton: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
    "&:hover, :focus": {
      backgroundColor: "rgba(211, 49, 48, 0.07)",
    },
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const LocationFormFields: React.FC<{
  index?: number;
  handleRemove?: () => void;
  locationValues?: any;
  gridView?: boolean;
}> = React.memo(({ index, handleRemove, locationValues, gridView }) => {
  const [collapsed, setCollapsed] = React.useState(() =>
    gridView ? false : Boolean(locationValues?.id)
  ); // Only existing locations will have an ID
  const classes = useStyles();
  const { manufacturerOptions } = useMachineOptions();
  const generateFieldName = React.useCallback(
    (name: string) =>
      typeof index === "number" ? `locations.${index}.${name}` : name,
    [index]
  );

  React.useEffect(() => {
    if (gridView) {
      setCollapsed(false);
    } else {
      setCollapsed(Boolean(locationValues?.id));
    }
    // eslint-disable-next-line
  }, [gridView]);

  const handleOpen = () => {
    setCollapsed(false);
  };
  const renderGrid = () => {
    if (!handleRemove) {
      return null;
    }
    if (!collapsed && locationValues?.id) {
      const handleCollapse = () => {
        setCollapsed(true);
      };
      return (
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button
              onClick={handleCollapse}
              size="small"
              variant="outlined"
              startIcon={<ArrowIcon />}
            >
              Collapse
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleRemove}
              variant="outlined"
              size="small"
              className={classes.removeButton}
            >
              REMOVE LOCATION
            </Button>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justify="flex-end">
        <Grid item>
          <Button
            onClick={handleRemove}
            variant="outlined"
            size="small"
            className={classes.removeButton}
          >
            REMOVE LOCATION
          </Button>
        </Grid>
      </Grid>
    );
  };
  if (collapsed) {
    return (
      <div className={classes.infoContainer}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography variant="caption" color="textSecondary">
              Name
            </Typography>
            <Typography className={classes.truncate} variant="body2">
              {locationValues.locationName}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="caption" color="textSecondary">
              Address
            </Typography>
            <Typography className={classes.truncate} variant="body2">
              {locationValues.address}
              {", "}
              {locationValues.city}
              {", "}
              {locationValues.state}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" color="textSecondary">
              Manufacturers
            </Typography>
            <Typography className={classes.truncate} variant="body2">
              {locationValues.manufacturers.join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.flexEnd}>
            <IconButton onClick={handleOpen}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        <div>
          <FormTextField
            name={generateFieldName("locationName")}
            label="Location Name"
            helperText="Don't include dealer name here - only location name"
          />
        </div>
        <div>
          <FormTextField name={generateFieldName("address")} label="Address" />
        </div>
        <div>
          <FormTextField name={generateFieldName("city")} label="City" />
        </div>
        <div>
          <FormSelectField
            name={generateFieldName("state")}
            label="State"
            options={states}
            native
          />
        </div>
        <div>
          <FormTextField name={generateFieldName("zip")} label="Zip Code" />
        </div>
        <div>
          <FormSelectField
            name={generateFieldName("manufacturers")}
            label="Manufacturers"
            options={manufacturerOptions}
            multiple
          />
        </div>
      </div>
      {renderGrid()}
    </div>
  );
});
export default LocationFormFields;
